//
// flot.scss
//

.flotTip {
  padding: 8px 12px;
  background-color: rgba($dark, 0.9);
  z-index: 100;
  color: $light;
  opacity: 1;
  border-radius: 3px;
}

.legend {
  tr {
    height: 30px;
    font-family: $font-family-secondary;
  }
}

.legendLabel {
  padding-left: 5px;
  line-height: 10px;
  padding-right: 20px;
  font-size: 13px;
  font-weight: $font-weight-medium;
  color: $gray-600;
}

.legendColorBox {
  div {
    border-radius: 50%;
    div {
      border-radius: 50%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .legendLabel {
    display: none;
  }
}
