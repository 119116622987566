//
// chartjs.scss
//

.chartjs-chart {
  margin: auto;
  position: relative;
  width: 100%;
}

.chartjs-chart-example {
  height: 300px;
}
