//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  padding-bottom: 60px;
  overflow-x: hidden;
}

.block-and-dim-all-prev-elements {
  background: rgba(0, 0, 0, 0.5) !important;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  right: -10px;
}
