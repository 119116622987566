$border-color: #dee2e6;
$card-border-color: #e4e7ea;


$unpaidWhite: #eee;
$expired: #fe9898;
$active: #78c350;
$inactive: #ff9800;
$disputed: #75629C;

$softOrange: #FFAA30;
$darkerSoftOrange: #E7992B;
$softPurple: #BC7BBF;
$darkerSoftPurple: #9D55A0;
$darkGrey: #585858;
$softGrey: #F7F7F7;
$dadaGrey: #DADADA;
$borderGrey: #DFE2E6;
$backgroundGrey: #F7F3EF;
$disabledGrey: #E5E5E5;
$disabledDarkGray: #757575;
$disabledBtnGrey: #ADADAD;
$softGreen: #6BD481;
$darkerSoftGreen: #6AC77E;
$softRed: #FD6767;