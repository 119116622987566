.manage-overview {
  .colored {
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .clickable {
    cursor: pointer;
    &:hover {
      i.fe-search {
        visibility: visible;
      }
    }

    i.fe-search {
      visibility: hidden;
      color: #0B1957;
      font-weight: 500;
      font-size: 120%;
    }
  }


  .red {
    background: rgba(253, 103, 103, 0.12);

  }

  .green {
    background: rgba(107, 212, 129, 0.15);
  }

  .yellow {
    background: rgba(255, 170, 48, 0.15);
  }
  .gray {
    background: #eee;
  }

  &.table.table.table-hover tbody tr:hover {
    box-shadow: none !important;
    background: #fff;
  }

  &.table td.p-0 {
    .form-group {
      padding: 0;
      margin: 0;
    }

    > div > div:not(.select) {
      padding: 8px;
    }
  }

  &.table th.p-0 {
    & > div > div {
      padding: 8px;
    }

    & > div > div:first-child {
      border-bottom: 1px solid #dee2e6;
    }
  }

  .annual-report-field {
    .react-select {
      padding: 0;
      margin: 0;
      width: 100%;

      .select__control {
        background: transparent;
        border: 0;

        &:hover {
          background: transparent;
        }
      }

      .select__control--is-focused {
        box-shadow: none;
      }

      .select__indicator-separator {
        display: none;
      }
    }
  }
}