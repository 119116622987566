@import "assets/scss/new_ui/colors.scss";

.initials-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-size: 12px;
  font-weight: 600;
  color: $blue-80;
  border: 1px solid $grey-40;
  background: white;

  &:hover {
    background: #f0f0f0;
    cursor: pointer;
  }

  &.denied {
    border: 1px solid $purple-80;
    background: $purple-6;
  }
  &.approved {
    border: 1px solid $green-40;
    background: rgba(107, 212, 129, 0.12);
    color: $green-80;
  }
  &.for-approval {
    border: 1px dashed $grey-10;
    background: $orange-4;
    color: $blue-80;
  }
}
