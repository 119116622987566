@import "assets/scss/new_ui/colors";

.onboarding-progress {
  display: flex;


  .progress {
    width: 28px;
    height: 6px;
    border-radius: 5px;
    margin-right: 5px;
    cursor: help;

    &:last-child {
      margin-right: 0;
    }

    &.in_progress {
      background: $orange-60;
    }

    &.not_started {
      background: $grey-10;
    }

    &.finished {
      background: $green-60;
    }

    &.error {
      background: $red-60;
    }
    &.locked {
      background: $grey-10;
    }

    &:hover {
      background: #0B1957;
    }
  }
}