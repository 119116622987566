$gray-350: #dee2e6;


@import "bflow/variables.scss";

// New Design
@import "new_ui/colors.scss";


@import "app.scss";
@import "bflow/ui.scss";
@import "bflow/layout.scss";
@import "bflow/animations.scss";
@import "bflow/table.scss";
@import "bflow/form.scss";
@import "bflow/modals.scss";
@import "bflow/dropzone.scss";
@import "bflow/reports.scss";
@import "bflow/extra.scss";
@import "bflow/buttons.scss";

.react-datepicker {
  .react-datepicker__day--keyboard-selected {
    border: 1px solid red !important;
  }

  .bank-matching {
    border-radius: 4px;
    border: 1.5px solid rgba(253, 103, 103, 0.30);
    padding: 2px 5px;
  }

  .bank-matching-count {
    color: #FD6767;
    font-weight: normal;
  }
}

.bank-matching-react-datepicker-popper {
  z-index: 9999 !important;
}

.qrCode img {
  width: 260px;
  height: 260px;
}

// New Design
@import "new_ui/buttons.scss";
@import "new_ui/navbar.scss";
@import "new_ui/utils";
@import "new_ui/form";
@import "new_ui/cards";
@import "new_ui/notifications";

