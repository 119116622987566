.company-information-step {
  .fa-question-circle {
    font-weight: 400;
    color: #adadad;
  }

  &__popover {
    border: 0;
    box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);

    .popover-body {
      font-size: 11px;
      color: #000;
    }

    .arrow {
      &::after,
      &::before {
        display: none;
      }
    }
  }
}
