.week-picker {
  width: 100%;
  font-size: 1.2em;

  .DayPicker {
    .DayPicker-wrapper {
      position: relative;
      flex-direction: row;
      user-select: none;

      .DayPicker-Months {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border: 0;

        .DayPicker-Month {
          flex: 1;
          display: table;
          margin: 1em 1em 0 1em;
          border-spacing: 0;
          //border-collapse: collapse;
          user-select: none;

          .DayPicker-Caption {
            display: table-caption;
            margin-bottom: 0.5em;
            padding: 0 0.5em;
            text-align: left;
          }

          .DayPicker-Weekdays {
            display: table-header-group;
            margin-top: 1em;

            .DayPicker-WeekdaysRow {
              display: table-row;

              .DayPicker-Weekday {
                display: table-cell;
                padding: 0.5em;
                color: #8b9898;
                text-align: center;
                font-size: 0.875em;

                abbr[title] {
                  border-bottom: none;
                  text-decoration: none;
                  cursor: help;
                  text-decoration-skip-ink: none;
                }
              }
            }
          }

          .DayPicker-Body {
            display: table-row-group;

            .DayPicker-Week {
              display: table-row;

              .DayPicker-WeekNumber {
                display: table-cell;
                padding: 0.5em;
                min-width: 1em;
                border-right: 1px solid #eaecec;
                color: #8b9898;
                vertical-align: middle;
                text-align: right;
                font-size: 0.75em;
                cursor: pointer;
              }

              .DayPicker-Day {
                display: table-cell;
                padding: 0.5em;
                border-radius: 0;
                vertical-align: middle;
                text-align: center;
                border-top: 1px solid transparent;
                border-bottom: 1px solid transparent;

                &:nth-child(2) {
                  border-left: 1px solid transparent;
                }

                &:last-child {
                  border-right: 1px solid transparent;
                }
              }

              .DayPicker-Day--outside {
                cursor: default;
                color: #ddd;
              }

              .DayPicker-Day--today {
                font-weight: 700;
              }

              .DayPicker-Day--hoverRange {
                background: #eee;
                border-color: #888;
                opacity: 0.9;

                &:nth-child(2) {
                  border-color: #888;
                }

                &:last-child {
                  border-color: #888;
                }
              }

              .DayPicker-Day--selected {
                border-color: #dc3545;
                opacity: 0.9;

                &:nth-child(2) {
                  border-color: #dc3545;
                }

                &:last-child {
                  border-color: #dc3545;
                }
              }

              .DayPicker-Day--unlocked {
                background-color: #3788d8;
                color: #fff;
              }

              .DayPicker-Day--future {
                color: #444;
              }

              .DayPicker-Day--locked {
                background: #28a745;
                color: #fff;
              }
            }
          }
        }
      }
    }

    .DayPicker-NavButton {
      position: absolute;
      top: 1em;
      right: 1.5em;
      left: auto;

      display: inline-block;
      margin-top: 2px;
      width: 1.25em;
      height: 1.25em;
      background-position: center;
      background-size: 50%;
      background-repeat: no-repeat;
      color: #8b9898;
      cursor: pointer;
    }

    .DayPicker-NavButton:hover {
      opacity: 0.8;
    }

    .DayPicker-NavButton--prev {
      margin-right: 1.5em;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC");
    }

    .DayPicker-NavButton--next {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==");
    }

    .DayPicker-NavButton--interactionDisabled {
      display: none;
    }

    .DayPicker-Caption {
      display: table-caption;
      margin-bottom: 0.5em;
      padding: 0 0.5em;
      text-align: left;
    }

    .DayPicker-Caption > div {
      font-weight: 500;
      font-size: 1.15em;
    }
  }
}
