.import-fortnox-step {
  .text-danger {
    color: #3d898a !important;
  }

  .fortnox-wrapper {
    .fortnox-control {
      margin-bottom: 4rem;

      &__start {
        margin-top: 0;
        margin-bottom: 1rem;

        @media (min-width: 1200px) {
          margin-top: 1.45rem;
        }

        i {
          font-size: 150%;
        }
      }
      .import-info {
        margin-bottom: 20px;
      }
    }

    .fortnox-results {
      &__item {
        font-size: 1rem;
        margin-bottom: 0.75rem;

        i {
          margin-right: 0.75rem;
        }

        &.in_progress {
          font-weight: 700;

          i {
            color: #585858;
          }
        }

        &.not_started {
          color: #adadad;

          i {
            color: #adadad;
          }
        }

        &.finished {
          font-weight: 500;

          i {
            color: #6bd481;
          }
        }

        &.error {
          color: #fd6767;

          i {
            color: #fd6767;
          }
        }
      }
    }
  }
}
