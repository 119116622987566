@import "colors";


.checkbox-primary {
  input[type=checkbox] {
    &:checked {
      & + label::before {
        background-color: $blue-40;
        border-color: $blue-40;
      }

      &:disabled {
        background: $grey-30;

        & + label {
          opacity: 1;
          color: $grey-40;
        }

        & + label::before {
          background-color: $grey-30;
          border-color: $grey-30;
        }
      }
    }
  }
}

.radio-primary {
  input[type=radio] {
    &:checked {
      & + label::before {
        border-color: $blue-40;
      }

      & + label::after {
        background-color: $blue-40;
      }
    }

    &:disabled {

      & + label {
        opacity: 1;
        color: $grey-40;
      }

      & + label::before {
        border-color: $grey-30;
      }

      & + label::after {
        background-color: $grey-30;
      }
    }
  }
}