//
// layouts.scss
//

body {
  &[data-layout-size="boxed"] {
    background-color: $boxed-body-bg;
    #wrapper {
      background-color: $body-bg;
      max-width: $boxed-layout-width;
      margin: 0 auto;
      box-shadow: $box-shadow;
    }

    .navbar-custom {
      max-width: $boxed-layout-width;
      margin: 0 auto;
    }

    .footer {
      margin: 0 auto;
      max-width: calc(#{$boxed-layout-width} - #{$leftbar-width});
    }

    &.enlarged {
      .footer {
        max-width: calc(#{$boxed-layout-width} - #{$leftbar-width-collapsed});
      }
    }
  }
}

// Horizontal Boxed Layout

body[data-layout="horizontal"][data-layout-size="boxed"] {
  #wrapper,
  .navbar-custom,
  .footer {
    max-width: 100%;
  }
  .container-fluid {
    max-width: $boxed-layout-width;
  }
}

.container-fluid:after {
  content: "";
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
  width: 100%;
  max-width: 100%;
  height: 8em;
}
