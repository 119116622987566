@import "src/assets/scss/new_ui/colors";

.missing-access-header {
  width: 50px;
  color: $red-100;
}

.expired-report {
  color: $red-100;
}

.wp-100 {
  width: 100px;
}

.wp-50 {
  width: 50px;
}

.office-support-export-table {
  i.chevron {
    padding-right: 30px;
    margin-left: -25px;
    color: rgba(173, 173, 173, 1);
  }
}