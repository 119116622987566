@import "assets/scss/new_ui/colors.scss";

.mandatory-options-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 22px;
  gap: 4px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid $blue-6;
  background: $blue-4;
  letter-spacing: -0.03em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: $blue-80;
  margin-right: 5px;

  &.badge-any {
    //&::before {
    //  content: "";
    //  display: inline-block;
    //  width: 12px;
    //  height: 12px;
    //  border: 1px solid $blue-10;
    //  border-radius: 50%;
    //}
  }

  &.badge-all {
    //&::before {
    //  content: "";
    //  display: inline-block;
    //  width: 12px;
    //  height: 12px;
    //  background: $blue-10;
    //  border-radius: 50%;
    //}
  }

  &.badge-other {
    //&::before {
    //  content: "";
    //  display: inline-block;
    //  width: 12px;
    //  height: 12px;
    //  border-radius: 50%;
    //  background: linear-gradient(to right, $blue-10 50%, transparent 50%);
    //}
  }

  &.badge-disabled {
    border: 1px solid $grey-30;
    background: $grey-10;
    color: $grey-60;

    &:hover {
      background: $grey-10;
      cursor: not-allowed;
    }

    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      background: $grey-30;
      border-radius: 50%;
    }
  }

  &.badge-error {
    border: 1px solid $red-40;
    color: $red-80;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.03em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    &:hover {
      background: $red-40;
      border-color: $red-60;
    }

    &::before {
      display: inline-block;
      color: $red-80;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
