//
// topbar.scss
//

// Logo
.logo {
  display: block;
  line-height: $topbar-height;

  span.logo-lg {
    display: block;
  }

  span.logo-sm {
    display: none;
  }

  .logo-lg-text-dark {
    color: $dark;
    font-weight: $font-weight-bold;
    font-size: 22px;
    text-transform: uppercase;
  }

  .logo-lg-text-light {
    color: $white;
    font-weight: $font-weight-bold;
    font-size: 22px;
    text-transform: uppercase;
  }

  &.logo-dark {
    display: none;
  }
}

.logo-box {
  height: $topbar-height;
  width: $leftbar-width;
  float: left;
  transition: all 0.2s ease-out;
}

.navbar-custom {
  background-color: $primary;
  box-shadow: 0 0 35px 0 rgba(52, 59, 74, 0.11);
  padding: 0 10px 0 0;
  position: fixed;
  left: 0;
  right: 0;
  height: $topbar-height;
  z-index: 100;

  .topnav-menu {
    > li {
      float: left;
    }

    .nav-link {
      padding: 0 15px;
      color: rgba($white, 0.6);
      min-width: 32px;
      display: block;
      line-height: $topbar-height;
      text-align: center;
      max-height: $topbar-height;
    }

    .lang-option {
      line-height: 70px;

      .bootstrap-select {
        .btn-light {
          background-color: lighten($primary, 1%);
          border-color: lighten($primary, 6%);
          color: rgba($white, 0.8);
          box-shadow: none;

          &:after {
            content: "\F140";
            font-family: "Material Design Icons";
            margin-top: -2px;
          }
        }
      }
    }
  }

  .dropdown.show {
    .nav-link {
      background-color: rgba($white, 0.05);
    }
  }

  /* Search */
  .app-search {
    overflow: hidden;
    height: $topbar-height;
    display: table;
    max-width: 180px;
    margin-right: 20px;

    .app-search-box {
      display: table-cell;
      vertical-align: middle;

      input::-webkit-input-placeholder {
        font-size: 0.8125rem;
        color: rgba($white, 0.7);
      }
    }

    .form-control {
      border: none;
      height: 38px;
      padding-left: 20px;
      padding-right: 0;
      color: $white;
      background-color: rgba($white, 0.07);
      box-shadow: none;
      border-radius: 30px 0 0 30px;
    }

    .input-group-append {
      margin-left: 0;
      z-index: 4;
    }

    .btn {
      background-color: rgba($white, 0.07);
      color: $white;
      border-color: transparent;
      border-radius: 0 30px 30px 0;
      box-shadow: none !important;
    }
  }

  .button-menu-mobile {
    border: none;
    color: $white;
    display: inline-block;
    height: $topbar-height;
    line-height: $topbar-height;
    width: 60px;
    background-color: transparent;
    font-size: 24px;
    cursor: pointer;
  }

  .button-menu-mobile.disable-btn {
    display: none;
  }
}

/* Notification */
.noti-scroll {
  max-height: 230px;
}

.notification-list {
  margin-left: 0;

  .noti-title {
    background-color: transparent;
    padding: 15px 20px;
  }

  .noti-icon {
    font-size: 21px;
    vertical-align: middle;
  }

  .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 16px;
    right: 10px;
  }

  .notify-item {
    padding: 12px 20px;

    .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      font-size: 18px;
      line-height: 36px;
      text-align: center;
      margin-top: 4px;
      margin-right: 10px;
      border-radius: 50%;
      color: $white;
    }

    .notify-details {
      margin-bottom: 5px;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $gray-800;
      font-weight: $font-weight-medium;

      b {
        font-weight: 500;
      }

      small {
        display: block;
      }

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
    }

    .user-msg {
      margin-left: 45px;
      white-space: normal;
      line-height: 16px;
    }
  }

  .profile-dropdown {
    .notify-item {
      padding: 7px 20px;
    }
  }
}

.profile-dropdown {
  .dropdown-item:focus {
    color: #6c757d;
    background-color: unset;
  }

  .dropdown-item:active {
    color: #6c757d;
  }

  .dropdown-item:hover {
    color: #6c757d;
    background-color: #f1f5f7;
  }

  width: 230px;

  span {
    font-weight: 500;
  }

  i {
    color: #adadad;
    margin-right: 10px;
    font-size: 20px;
    font-weight: 400;
    vertical-align: middle;
  }

  .dropdown-item-start-page-options:hover {
    background-color: unset;
  }

  .start-page-options {
    text-align: center;

    button {
      height: 38px;
      border-radius: 6px;
    }

    i {
      font-size: 16px;
      vertical-align: middle;

      &.os-icon {
        display: inline-block;
        background-image: url("../../../images/OS-icon.svg");
        filter: brightness(0) saturate(100%) invert(90%) sepia(1%) saturate(7376%) hue-rotate(206deg) brightness(122%) contrast(36%);
        width: 34px;
        height: 34px;
      }
    }

    button:hover {
      background-color: rgba(52, 140, 212, 0.2);
    }

    button.not-selected {
      width: 38px;
      min-width: 38px !important;
      padding: 0 !important;
      border-color: #eceded;

      i {
        color: #adadad;
      }
    }

    button.selected {
      width: 54px;
      min-width: 54px !important;
      padding: 0 !important;
      background-color: rgba(52, 140, 212, 0.07);
      border-color: #0B1957;

      i {
        color: #757575;

        &.os-icon {
          filter: brightness(0) saturate(100%) invert(44%) sepia(39%) saturate(0%) hue-rotate(188deg) brightness(91%) contrast(58%);
          margin-left: -10px;
        }
      }

      .fe-check {
        color: #0B1957;
        margin-right: 3px;
      }
    }

    button:not(:last-child) {
      margin-right: 3px;
    }

    i {
      margin-right: 0;
    }
  }

  .forced-tooltip {
    position: absolute;
    background: #51b0fe;
    padding: 10px 20px;
    right: 237px;
    top: 40px;
    border-radius: 4px;
    color: white;
    width: 330px;

    .btn {
      margin-top: 3px;
      float: right;
    }

    .col {
      p {
        margin: 0;
        width: 210px;
        white-space: pre-line;
      }
    }
  }

  .fa-angle-up {
    font-weight: 500;
  }

  .forced-tooltip:after {
    content: "";
    position: absolute;
    border-top: 8px solid transparent;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #51b0fe;
    transform: rotate(90deg);
    right: -16px;
    top: 35px;
  }

  .toogleShowMoreOptions {
    padding: 10px;
  }
}

.nav-user {
  padding: 0 12px !important;

  img {
    height: 32px;
    width: 32px;
  }

  .user-icon {
    display: inline-block;
    vertical-align: middle;
    height: 32px;
    width: 32px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 50px;
    line-height: 38px;

    i {
      font-size: 20px;
      color: #fff;
    }
  }
}

// Topbar Navbar light

body[data-topbar="light"] {
  .navbar-custom {
    background-color: $bg-topbar-light;

    .topnav-menu {
      .nav-link {
        color: $gray-700;
      }

      .lang-option {
        .bootstrap-select {
          .btn-light {
            background-color: darken($bg-topbar-light, 4%);
            border-color: darken($bg-topbar-light, 6%);
            color: $gray-700;

            &:after {
              content: "\F140";
              font-family: "Material Design Icons";
              margin-top: -2px;
            }
          }
        }
      }
    }

    .button-menu-mobile {
      color: $gray-600;
    }

    /* app search */
    .app-search {
      input::-webkit-input-placeholder {
        color: $gray-600 !important;
      }

      .form-control {
        color: $dark;
        background-color: darken($bg-topbar-light, 4%);
        border-color: $gray-100;
      }

      .btn {
        background-color: darken($bg-topbar-light, 4%);
        color: $gray-600;
      }
    }
  }
}
