.signing-basket-modal {
  position: relative;
  max-width: 1000px;

  .modal-content {
    min-height: 400px;
    padding-bottom: 10px;
    border-radius: 8px;
    overflow-y: auto;
  }

  .close-btn {
    padding: 0;
    width: 40px;
    height: 40px;
    z-index: 9;
    position: absolute;
    border: 0;
    background: #fff;
    top: 0;
    right: 0;
    color: #757575;

    &:hover {
      color: #000;
      cursor: pointer;
    }
  }

  .title {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 18px;
      line-height: 21px;
      font-weight: 600;
      margin-bottom: 6px;
    }

    h4 {
      margin-top: 0;
      font-size: 13px;
      line-height: 20px;
      font-weight: 600;
      color: #757575;

      &.text-error {
        color: #f93636;
      }
    }
  }

  .modal-footer {
    border-top: 0;
    justify-content: center;
  }

  table {
    color: #1b1b1b;

    tr.has-error {
      background: rgba(249, 54, 54, 0.05);
    }

    tr.state {
      i {
        font-weight: 600;
        font-size: 16px;
      }

      &--partially_signed,
      &--pending {
        i {
          &.fe-x {
            display: none;
          }

          color: #ffaa30;
        }
      }

      &--sent_bank,
      &--paid {
        i {
          &.fe-x {
            display: none;
          }

          color: #6bd581;
        }
      }

      &--fail {
        i {
          &.fe-check-circle {
            display: none;
          }

          color: #f93636;
        }
      }
    }

    th {
      border: 0;
      color: #adadad;
    }

    td {
      border-top: 0;
      padding: 0.6rem 0.85rem;
      border-bottom: 1px solid #eceded;

      .text-warn,
      .text-error {
        margin: 0;
        padding: 0;
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
      }

      .text-warn {
        color: #757575;

        i {
          margin-right: 6px;
        }
      }

      .text-error {
        color: #f93636;
      }

      .react-datepicker-wrapper {
        float: right;
        width: 110px;

        input {
          text-align: right;
        }
      }
    }

    td.trash-item {
      width: 50px;
      text-align: center;

      button {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        border: 0;
        background: transparent;
        color: #f93636;

        &:hover {
          font-weight: 600;
        }
      }
    }

    th.total {
      font-size: 13px;
      font-weight: 500;
      color: #0B1957;
    }
  }

  .stage-decoupled {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    img {
      height: 250px;
      width: 250px;
    }

    .bankId-loader {
      img {
        height: 100px;
        margin-bottom: 1rem;
      }
    }
  }
}
