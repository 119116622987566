.btn-custom {
  padding: 0 10px 2px 8px;
  gap: 4px;
  border-radius: 4px;
  border: 0;
  background-color: #f3f3f3;
  color: #757575;
}

.btn-custom-active,
.btn-custom:hover,
.btn-custom-active:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.btn-custom-active:focus,
.btn-custom.btn-custom-active:active,
.btn-custom:focus-visible,
.btn-custom.btn-custom-active:focus-visible {
  background-color: #E5E5E5 !important;
  color: #585858!important;
}
