@import "assets/scss/new_ui/colors.scss";

.authentication-bg2 {
  background-image: url("/assets/images/auth-bg.png");
  background-color: $blue-80;
  background-repeat: no-repeat;
  background-position: 90%;
  background-size: 35%;
  height: 100vh;

  .underline {
    text-decoration: underline;
  }

  .modal-backdrop {
    background: #1b1b1b;
  }

  a {
    color: $grey-40;
  }

  #wrapper {
    overflow: hidden;
    height: 100%;
    position: relative;

    .auth-layout {
      display: flex;
      flex-direction: column;
      padding: 60px;
      height: 100%;

      .header {
        display: flex;
        justify-content: space-between;

        a {

          &.home {
            i {
              color: #fff;
            }
          }

          &.logo {
            img {
              height: 26px;
            }
          }
        }
      }

      .body {
        display: flex;
        flex: 1;
        align-content: center;
        align-items: center;

        .box {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 300px;
          max-width: 350px;
          width: auto;
          margin: 120px;

          &.w-350 {
            width: 300px;
          }
          &.box-pwd {
            max-width: 350px;
          }

          &.box-pwd {

            .checkbox {
              padding-left: 0;
              margin-left: 6px;
              color: #fff;
            }

            .form-group {
              width: 100%;
            }
          }

          h3 {
            color: #fff;
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 1.5rem;
          }

          button {
            width: 100%;
            border-radius: 4px;

            &.bankid {
              line-height: 16px;
              .bankid-black {
                display: inline-block;
              }

              .bankid-white {
                display: none;
              }

              &:hover {
                .bankid-black {
                  display: none;
                }

                .bankid-white {
                  display: inline-block;
                }
              }

              overflow: hidden;

              img {
                margin-right: 10px;
              }
            }
          }

          .inner-box {
            width: 100%;
            display: flex;
            flex-direction: column;
          }

          .form-group {
            margin-bottom: .3rem;

            &.is-invalid input {
              border-color: $red-60 !important;
            }

            .invalid-feedback {
              color: $red-60;
            }
          }

          input.form-control {
            color: #fff;
            line-height: 1.5;
            font-size: 14px;

            &::placeholder {
              color: $grey-40;
            }

            &:hover, &:active {
              background: #323D6F;

            }

            &:focus {
              border-color: #fff !important;
            }

            width: 100%;
            background: $blue-80;
            border: 1px solid $grey-40 !important;
            box-shadow: 0 2px 4px 0 #00000005;

          }

          button {
            font-size: 1rem;
            margin-top: 2rem;
            flex: 1;
            max-width: 100%;
          }

          .d-flex.justify-content-between {
            button {
              + button {
                margin-left: 1rem;
              }

              margin-left: 0;
            }
          }

          a.side-link {
            margin-top: 4rem;
            color: rgba(255, 255, 255, 0.5);
            font-weight: 400;
            font-size: 13px;
            line-height: 21px;

            &:hover {
              color: #fff;
            }
          }
        }
      }

      .language-switcher {
        display: flex;

        button {
          color: rgba(255, 255, 255, 0.5);

          &:hover {
            color: #fff;
          }
        }
      }

    }
  }

  .public-notifications {
    .alert {
      padding: 12px;
      font-weight: 400;
      color: #313144;
      margin-bottom: 0;
      border-radius: 8px;
      font-size: 13px;
      line-height: 20px;
      display: flex;

      i {
        font-weight: 500;
        font-size: 17px;
      }

      &.alert-danger {
        i {
          color: #FD6767;
        }

        border-color: #FD6767;
        background: #ffecec;
      }
    }
  }
}

@media (min-width: 992px) {
  .authentication-bg2 .modal-lg {
    max-width: 650px;
  }
}

@media (max-width: 992px) {
  .authentication-bg2 {
    background-image: none;
  }

  .authentication-bg2 #wrapper .auth-layout .body .box {
    margin: 0 auto;
  }
}