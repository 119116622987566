.time-report-calendar {
  .fc-timegrid {
    margin-top: 0;
  }

  .fc-scrollgrid-section-header {
    .fc-scroller {
      overflow: hidden !important;
    }
  }

  .fc-col-header-cell-cushion {
    width: 100%;
    position: relative;
  }

  .day {
    cursor: pointer;

    &.locked {
      color: #28a745;
    }

    &__name {
      text-transform: uppercase;
      font-size: 0.7rem;
      line-height: 1rem;
    }

    &__month {
      font-size: 2rem;
      line-height: 2rem;
    }

    &__lock {
      border-bottom: 1px solid #ddd;
      font-size: 1rem;
      line-height: 2rem;
    }

    &__summary {
      vertical-align: middle;
      font-weight: 500;
      color: #666;

      p {
        border-top: 1px solid #eee;
        cursor: help;
        font-size: 0.7rem;
        padding: 0;
        margin: 0;
        line-height: 1.4rem;
      }
    }
  }

  .fc-event {
    background: #0B1957;
    cursor: pointer;

    &.fc-locked {
      cursor: not-allowed;
      background: #28a745;
    }

    &.fc-bg-event {
      .fc-event-main-frame {
        display: none;
      }
    }

    .fc-event-main {
      padding: 0;
      margin: 0;
    }
  }
}
