.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.1;
  }
}

@keyframes stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 60px 30px;
  }
}
