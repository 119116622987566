.step-progress-bar {
  display: flex;
  align-items: center;
  margin: 0 0 1rem 0;

  .progress {
    flex: 1;
    height: 3px;

    .progress-bar {
      background-color: #456EE0;
    }
  }

  .progress-title {
    border-radius: 5px;
    padding: 2px 6px;
    background: #456EE03D;
    color: #0B1957;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-left: 1rem;
  }
}