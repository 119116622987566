.skip-tooltip {
  .arrow {
    display: none;
  }

  .tooltip-inner {
    background: #484848;
    color: #FFFFFF;
    max-width: 243px;
    padding: 0.4rem 0.8rem;
    text-align: left;
    border-radius: 0.2rem;
  }
}
