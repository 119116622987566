.profit-loss {
  margin-bottom: 0;

  .editMode {
    background: rgba(255, 255, 255, .5);
    position: fixed;
    right: 40px;
    bottom: 55px;
  }


  .search-input-wrapper {
    display: inline-block;
    .search-input.form-control {
      width: 150px !important;
    }
  }

}