//
// accordions.scss
//

.custom-accordion {
  .card {
    + .card {
      margin-top: 0.5rem;
    }
  }
  .accordion-arrow {
    font-size: 1.2rem;
  }
  a {
    &.collapsed {
      i.accordion-arrow {
        &:before {
          content: "\F142";
        }
      }
    }
  }
}
