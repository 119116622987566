.offer-modal.modal {
  .modal-content {
    border-radius: 10px;
  }
  .modal-header {
    border-bottom: none;

    .modal-title {
      color: #1b1b1b;
      font-size: 18px;
      flex: 1;
      text-align: center;
      font-weight: 600;
    }
  }
  .modal-body {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.01em;
    text-align: center;
    color: rgba(88, 88, 88, 1);
    margin: 10px 20px 20px;
  }
  .modal-footer {
    display: flex;
    justify-content: space-between;
    border-top: none;
    margin-bottom: 30px;

    .offer-modal-cancel {
      color: rgba(88, 88, 88, 1);
      font-size: 13px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      margin-left: 16px;
    }
    .offer-modal-action {
      margin-right: 20px;
    }
    .action-spacing {
      margin-left: -10px;
    }
  }
}
