
.status-list-select {
  border: 0;

  .select__control {
    min-height: auto;
    background: transparent;
  }

  .select__indicator {
    padding: 0;
  }

  .select__option {
    color: #4D4E66;
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;

    .period-tag {
      margin-right: .5rem;
    }

    &--is-focused {
      background: #456EE00E;
    }

    &--is-selected {
      background: #456EE01F;
    }
  }

  .select__indicators span {
    display: none;
  }

  .select__value-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    .select__single-value {
      flex: 1;
      text-align: right;
    }

  }

  .period-tag {
    border-radius: 4px;
    background: #F3F4F4;
    color: #ACACAC;
    padding: 0 6px;

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }

    &.green {
      background: #6BD4811A;

      span {
        color: #60BD74;
      }
    }

    &.border-warn {
      border: 1px dashed #FFAA30;
    }

    &.border-danger {
      border: 1px solid #FD6767;
    }

    &.purple {
      background: #C670CA1A;

      span {
        color: #C670CA;
      }
    }
  }

  .status-tag {
    font-size: 14px;
    line-height: 21px;
    color: #0B1957;
    text-overflow: ellipsis;
    overflow: hidden;

    span {
      &:first-child {
        font-weight: 500;
        color: #0B1957
      }

      &:nth-child(2) {
        font-weight: 500;
        color: #AAB0C6;
      }
    }
  }
}

.status-list-detail-modal {
  .react-select {
    .select__control {
      height: 39px;
    }

    .select__single-value {
      color: #0B1957;
      font-weight: 400;
    }
  }

  .react-select, .status-list-select {
    width: 300px;

    .select__indicator-separator {
      display: none;
    }

    .select__indicator {
      color: hsl(0, 0%, 80%);
    }
  }


  .status-list-select {
    border: 1px solid #ced4da;
    border-radius: 0.3rem;
    padding: .3rem .5rem;

    .select__single-value {
      font-weight: 500;
      color: #0B1957;
    }

    .period-tag {
      margin-right: 4px;
    }

    .select__single-value {
      text-align: left;
    }
  }
}


.popover-deadline {
  box-shadow: 0px 2px 8px 0px #0000003D;

  .arrow {
    display: none;
  }

  div.status {
    color: #313144;
    font-weight: 500;
    font-size: 13px;
  }

  div.deadline {
    color: #7C8296;
    font-size: 13px;
    line-height: 18px;
  }

  i {
    font-weight: 500;
    font-size: 16px;

    &.green {
      color: #60BD74;
    }

    &.warn {
      color: #FFAA30;
    }

    &.danger {
      color: #FD6767;
    }
  }
}