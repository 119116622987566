.connect-box {
  margin-right: 1rem;
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  min-height: 110px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ADADAD;


  .title {
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    color: #1B1B1B;
  }

  .subtitle {
    font-size: 10px;
    font-weight: 400;
    color: #adadad;
  }

  &.connection-ok {
    border-color: #0B1957;

    .title i {
      color: #0B1957;
    }

    button.renew {
      display: none;
    }
  }

  &.connection-warning {
    border-color: #FFAA30;

    button.renew {
      border-radius: 5px;
      background: #FFAA30;
      border-color: #FFAA30;

      &:hover {
        background: #f28e00;
      }
    }

    .title i {
      color: #FFAA30;
    }
  }

  &.connection-danger {
    button.renew {
      background: #F93636;
      border-color: #F93636;

      &:hover {
        background: #eb0707;
      }
    }

    .title i {
      color: #FFAA30;
    }
  }

  .box-container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button {
      display: block;
    }

    .box-info {
      display: flex;
      flex-direction: column;
      color: #757575;

      span {
        display: inline-block;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: #000;
      }
    }
  }

  button.connect {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    margin-top: 1rem;
    border-radius: 5px;
  }
}