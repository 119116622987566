@import "assets/scss/new_ui/colors";

.company-notification-bar {
  font-weight: 400;
  margin-left: -30px;
  margin-right: -30px;
  padding: 8px 0;
  text-align: center;
  color: $blue-100;
  font-size: 13px;
  background: $red-6 !important;
  border-bottom: 1px solid $red-8;
  i, a {
    color: $red-80;
  }
  i {
    font-size: 16px;
    margin-right: .5rem;
  }

  a {
    font-weight: 600;
    text-decoration: underline;
  }

  &.bg-warning {
    background-color: $orange-6 !important;
    border-bottom: 1px solid $orange-8;
    i {
      color: $orange-80;
    }

    a {
      color: $orange-80;
    }


  }

}
