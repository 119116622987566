.stepper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  list-style-type: none;
  position: relative;
  padding: 0 83px 34px 83px;
}

.stepper li {
  flex: 1;
  position: relative;
  font-weight: bold;
  color: #dbdbdb;
  padding-right: 7px;
}

.stepper li.active {
  color: #757575;
}

.stepper li.completed {
  color: #60bd74;
}

.stepper li .step-bar {
  width: 100%;
  height: 2px;
  border-radius: 10px;
  z-index: 2;
  background: #DBDBDB;
}

.stepper li.completed .step-bar {
  background: #60bd74;
}

.stepper li.active .step-bar {
  background: #757575;
}

.stepper li.active:before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: -6px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #0B1957;
}

.stepper li span {
  display: block;
  margin-top: 10px;
  position: relative;
  z-index: 1;
}

.stepper li:first-child .step-bar {
}

.stepper li:last-child .step-bar {
}

.step-upload-container .dropzone-wrapper {
  width: 50%;
  margin: auto;
  padding: 30px 40px 30px 40px;
  gap: 10px;
  border-radius: 6px 0 0 0;
  border: 1px dashed #ADADAD;
  background: #F7F7F7;
}

.step-upload-cancel-button {
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: #585858;
  float: right;
}

.upload-skv-file-modal {

  .modal-header {
    border-bottom: 0;

  }

  .modal-footer {
    border-top: 0;
    justify-content: right;
  }

  .modal-dialog-scrollable .modal-body {
    padding-top: 0;
    margin-right: 10px;
  }

  ::-webkit-scrollbar {
    width: 3px;
    padding-right: -3px;
  }

  ::-webkit-scrollbar-thumb {
    background: #DBDBDB;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #DBDBDB;
  }
}

.error-upload-message div {
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
}

.rot-rut-matching-table table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  position: relative;
}

.rot-rut-matching-table thead th {
  border: 1px solid #EDEDED;
  border-bottom: 2px solid #EDEDED;
}

.rot-rut-matching-table td {
  padding: 8px;
  color: #585858;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
}

.rot-rut-matching-table tbody tr td {
  border: 1px solid #EDEDED;
}

.rot-rut-matching-table th {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  background-color: #FFFFFF;
  color: #ADADAD;
  position: sticky;
  top: -1px !important;
  z-index: 99999;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.rot-rut-matching-table tbody tr.application-name {
  background-color: #FFFFFF;
}

.rot-rut-matching-table tbody tr.application-name td {
  background: #FCFCFC;
  color: #ADADAD;
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
}

.rot-rut-matching-table tbody tr.total-approved {
  border-top: 2px solid #0B1957 !important;
  background-color: #0B19571A;
  color: #585858;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
}

.rot-rut-matching-table tbody tr.total-approved td {
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
}

.rot-rut-matching-table tbody tr.empty {
  height: 22px;
  border-left: 0;
  border-right: 0;
}

.rot-rut-matching-table tbody tr.empty td {
  border: 0;
}

.rot-rut-matching-table tbody tr td input[type="checkbox"] {
  margin: 0;
}

.rot-rut-matching-table tbody tr.disabled {
  background-color: #ECEDED99;
  color: #ADADAD;
}

.rot-rut-matching-table tbody tr.disabled td {
  background-color: #ECEDED99;
  color: #ADADAD;
}

.rot-rut-matching-table tbody tr td.warning {
  color: #585858;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;
  background: #FFAA3024;
  border: 1px solid #EDEDED;
}

.rot-rut-matching-table tbody tr td.warning-border {
  padding: 0;
  border: 1px solid #EDEDED;
}

.rot-rut-matching-table tbody tr td.warning-border .warning-border-content {
  background-color: #fff8e6;
  padding: 8px;
  border-radius: 4px;
  font-weight: bold;
  color: rgba(255, 170, 48, 1);
  transition: background-color 0.3s, color 0.3s;
  border: 1px solid #EDEDED;
}

.rot-rut-matching-table tbody tr td.warning-border .warning-border-content:hover {
  background-color: #fff1b8;
  color: #FF8C00;
  border: 1px solid #EDEDED;
}
