.archive-switcher {
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
  font-size: 110%;

  > div {
    &:hover {
      color: #444;
      cursor: pointer;
    }
  }

  i {
    color: #007BFFFF;
    font-weight: 600;
  }
}