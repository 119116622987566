.account-matching-form {
  &__suggested-row {
    .select {
      background: rgba(255, 170, 48, 0.1);
    }
  
    .table-select__control {
      background-color: transparent;
    }

    .confirm-button {
      position: absolute;
      right: 11px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1000;
    }
  }
}
