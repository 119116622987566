.modal-header {
  &.custom-modal-header {
    border-bottom: 0;
    padding-bottom: 0;
  }

  &.modal-header-control {
    border-bottom: 1px solid #f5f3f3;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    display: block;

    .fileName {
      font-size: 100%;
      color: #ADADAD;
      padding-bottom: 1rem;
    }

  }
}