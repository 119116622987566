//
// components-demo.scss
//

// Demo Only
.button-list {
  margin-left: -8px;
  margin-bottom: -12px;

  .btn {
    margin-bottom: 12px;
    margin-left: 8px;
  }
}

.switchery-demo {
  .switchery {
    margin-top: 4px;
    margin-right: 4px;
  }
}

// Icon demo ( Demo only )
.icons-list-demo {
  div {
    cursor: pointer;
    line-height: 45px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    p {
      margin-bottom: 0;
      line-height: inherit;
    }
  }
  i {
    text-align: center;
    vertical-align: middle;
    font-size: 22px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin-right: 12px;
    color: rgba($dark, 0.7);
    border-radius: 3px;
    display: inline-block;
    transition: all 0.2s;
  }
  .col-lg-4 {
    background-clip: padding-box;
    margin-top: 10px;
    &:hover i {
      color: $white;
      background-color: $primary;
    }
  }
}

/* Icon colored demo */
.icon-colored {
  height: 48px;
  width: 48px;
  margin: 1.4rem;
}

// Grid

.grid-structure {
  .grid-container {
    background-color: $gray-300;
    margin-top: 10px;
    font-size: 0.8rem;
    font-weight: $font-weight-medium;
    padding: 10px 20px;
  }
}

/* Popover */

.demo-popover {
  .popover {
    position: relative;
    display: block;
    float: left;
    width: 260px;
    margin: 20px;
    z-index: 9;
  }
}

/* Tooltips */

.demo-tooltip {
  .tooltip {
    position: relative;
    display: inline-block;
    margin: 10px 20px;
    opacity: 1;
  }
}

// Demos button
.demos-show-btn {
  position: fixed;
  top: 50%;
  right: 0;
  writing-mode: vertical-rl;
  font-weight: 600;
  background-color: $danger;
  color: $white !important;
  line-height: 36px;
  padding: 15px 3px;
  border-radius: 6px 0 0 6px;
  transform: translateY(-50%);
  text-transform: uppercase;
}

@media (max-width: 600px) {
  .demos-show-btn {
    display: none;
  }
}
