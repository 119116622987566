.skatteverket-connect-modal {
  .modal-lg {
    max-width: 650px;
  }

  .modal-content {
    .modal-header {
      border-bottom: 0;

      .agency-info {
        color: #585858;
        padding: 0 30px;
        font-size: 11px;
        text-align: center;
      }
    }

    .modal-body {
      text-align: center;
      padding-left: 4rem;
      padding-right: 4rem;

      .step-counter {
        font-size: 18px;
        text-align: center;
        color: #adadad;
        font-weight: 600;

        strong {
          color: #1b1b1b;
        }
      }

      .step-info {
        padding-left: 80px;
        padding-right: 80px;
        margin-bottom: 0;
        font-weight: 500;
        color: #1b1b1b;
      }

      ul {
        font-size: 13px;
        padding-left: 80px;
        padding-right: 80px;

        li {
          margin-bottom: 5px;
          text-align: justify;

          strong {
            font-weight: 700;
            color: #1b1b1b;
          }
        }
      }

      a {
        font-size: 14px;
        font-weight: 700;
        text-decoration: underline;
      }

      .next-step {
        padding-left: 40px;
        padding-right: 40px;
        border-radius: 5px;
        font-weight: 600;
      }

      .prev-step {
        color: #757575;
      }

      .step-progress {
        margin-top: 3rem;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: row;
        width: 100%;

        .step {
          margin-right: 6px;
          flex: 1;
          min-width: 50px;
          height: 6px;
          border-radius: 5px;
          background: #ECEDED;

          &.active {
            background: #0B1957;
          }
        }
      }

      &.step-3 {
        ul li {
        }
      }
    }
  }
}