.notes {
  .notes-scroller {
    padding-top: 2px;
    max-height: 56vh;
    min-height: 56vh;
    overflow-y: auto !important;
  }

  .notes-scroller::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .notes-scroller {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .upload-btn {
    &:hover {
      cursor: pointer;
      color: rgba(52, 100, 212, 1);
    }
    color: rgba(52, 140, 212, 1);
    padding-top: 2px;
    &.disabled {
      color: rgba(219, 219, 219, 1);
      pointer-events: none;
      cursor: default;
    }
  }
  .table-filters-group {
    margin-bottom: 0;
    &.disabled {
     .select__control {
        background-color: #F7F7F7;
      }
      .form-control {
        background-color: #F7F7F7;
      }
      .checkbox label::before {
        background-color: #F7F7F7;
      }
      pointer-events: none;
    }
  }
  .checkbox {
    margin-top: 18px;
  }
  hr {
    border-top: 1px solid rgb(255, 170, 48);
  }
  .fe-edit-2 {
    color: rgba(117, 117, 117, 1);
    &:hover {
      color: rgba(52, 140, 212, 1);
    }
  }
  .fe-trash {
    color: rgba(117, 117, 117, 1);
    &:hover {
      color: red;
    }
  }
  .fe-archive {
    color: rgba(117, 117, 117, 1);
    &:hover {
      color: rgba(52, 140, 212, 1);
    }
  }
  .unarchive-btn {
    .fe-rotate-cw {
      color: rgba(52, 140, 212, 1);
    }
  }

  .note-card {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-radius: 4px;
    &.shadowed {
      -webkit-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
    }
    &.hover-allowed:hover,
    &.hover-allowed:focus-within {
      background: rgba(250, 250, 250, 1);
    }

    &.archived {
      background-color: #eceded !important;
      .note-row {
        .initialsCircle {
          background-color: rgba(219, 219, 219, 1);
        }
        p {
          color: rgba(117, 117, 117, 1) !important;
        }
        .badge {
          &.badge-category {
            background-color: rgba(219, 219, 219, 1);
          }
          &.badge-attachment {
            background-color: rgba(219, 219, 219, 1);
          }
        }
      }
    }
    &.disabled {
      margin-bottom: 6px;
      background-color: #FAFAFA !important;
      .note-row {
        p {
          color: #ADADAD !important;
        }
      }
      .badge-file-name {
        color: #ADADAD !important;
      }
      .showHideToggleChildren {
        color: #ADADAD !important;
        pointer-events: none;
      }
      .badge-category {
        background-color: #ECEDED;
      }
      .child-container {
        p {
          color: #ADADAD !important;
        }
      }
    }
  }

  .parent-note-container-create {
    .disabled {
      pointer-events: none;
      .text-and-upload-field-container .row {
        background-color: #F7F7F7;
      }
      .select__control {
        background-color: #F7F7F7;
      }
      .form-control {
        background-color: #F7F7F7;
      }
      .checkbox label::before {
        background-color: #F7F7F7;
      }
    }

    z-index: 10000;
    padding-top: 10px;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    border-radius: 0;
    .new-attachment-row {
      padding-top: 5px;
      padding-right: 0;
    }
    .text-and-upload-field-container {
      padding-top: 1px;
      .row {
        border: 1px solid rgba(207, 212, 217, 1);
      }
    }
    .label-create {
      margin-bottom: 0.2rem;
      margin-left: -1px;
    }

    .card {
      border-radius: 0;
      padding: 12px 40px 12px 40px;
      margin-bottom: -25px;
    }

    .submit-col {
      margin-top: 24px;
      max-width: 190px;
    }
    .category {
      max-width: 250px;
    }

    .badge-file-name-col {
      max-width: 450px !important;
    }
  }

  .onHoverIcon {
    .showOnHover {
      display: none;
    }
    &:hover,
    &:focus-within {
      .showOnHover {
        display: inline;
      }
    }
  }

  .initialsCircle {
    width: 28px;
    height: 28px;
    line-height: 28px;
    border-radius: 50%;
    color: rgba(88, 88, 88, 1);
    text-align: center;
    vertical-align: middle;
    background: rgba(52, 140, 212, 0.15);
    font-weight: 600;
    font-size: 13px;
  }
  .initialsCol {
    max-width: 30px;
    padding-top: 5px;
    padding-left: 5px;
  }

  .note-row {
    margin-right: 0;
    margin-left: 0;
    padding-top: 10px;
    .upload-btn {
      &:hover {
        cursor: pointer;
        color: rgba(52, 100, 212, 1);
      }
      color: rgba(52, 140, 212, 1);
      &.disabled {
        color: rgba(219, 219, 219, 1);
        pointer-events: none;
        cursor: default;
      }
    }
    .form-label {
      display: none !important;
    }
    p {
      word-break: keep-all;
      overflow: hidden;
      white-space: pre-line;
      color: black;
      margin-top: 7px;
      margin-bottom: 8px;
    }
    .category {
      max-width: 250px;
    }
    .showAndAddChildrenRow {
      padding-top: 0;
      padding-bottom: 3px;
      margin-left: -16px;
      color: rgba(52, 140, 212, 1);
      font-weight: 600;
      i {
        color: rgba(173, 173, 173, 0.9);
        &.fe-plus {
          color: rgba(52, 140, 212, 1);
        }
      }
      .showHideToggleChildren {
        margin-right: 4px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .right-side-column {
    max-width: 325px;
    min-width: 325px;
    text-align: left;
    word-break: break-all;
    overflow: hidden;
    white-space: pre-line;
    .actions-buttons {
      .btn {
        width: 20px;
        height: 20px;
        padding: 1px 12px 1px 12px;
        i {
          font-size: medium;
        }
      }
    }
  }

  .text-and-upload-field-container {
    padding-left: 9px;
    margin-bottom: 5px;
    .autoHeightArea {
      padding-top: 7px;
    }
    .autoHeightAreaEditable {
      color: black;
      background-color: transparent !important;
    }
    .row {
      border: 2px solid rgba(52, 140, 212, 0.3);
      border-radius: 4px;
      min-height: 38px;
      .file_upload_col {
        padding-top: 6px;
        padding-left: 5px;
        max-width: 30px;
      }
    }
  }

  .child-container {
    padding-left: 80px;
    padding-top: 7px;

    .right-side-column {
      padding-left: 21px;
      padding-top: 6px;
    }
    .text-and-upload-field-container {
      padding-left: 20px;
      margin-bottom: 0;
      .row {
        .col:first-child {
          padding-top: 2px;
          margin-top: -3px;
          margin-bottom: -3px;
          padding-left: 7px;
        }
      }
    }
    .number-col {
      font-weight: 400;
    }

    p {
      word-break: keep-all;
      overflow: hidden;
      white-space: pre-line;
      color: black;
      margin-top: 6px;
      //padding-top: 3px;
    }

    .extra-icons-row {
      padding-top: 9px;
      text-align: right;
      max-width: 100px;
      .btn {
        width: 20px;
        height: 20px;
        padding: 1px 10px 1px 10px;
        i {
          font-size: medium;
        }
      }
    }
    .submit-col {
      padding-top: 2px;
      max-width: 90px;

      button {
        border: unset;
        background: white;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
        padding: 0;
        width: 30px;
        height: 30px;
        color: rgba(98, 98, 98, 1);
        &:first-of-type {
          margin-right: 5px;
        }
      }
    }
    .child-row {
      margin-right: 10px;
      margin-left: -10px;
      &.hover-allowed:hover {
        padding-top: 6px;
        background: white;
        border-radius: 5px;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.11);
      }
    }
  }

  .created {
    padding-left: 10px;
    color: #adadad;
  }

  .children-row {
    padding-left: 80px;
    padding-right: 30px;
    .right-side-column {
      padding-left: 0;
    }
  }

  .number-col {
    max-width: 45px;
    padding-right: 3px;
    padding-top: 5px;
    padding-left: 0px;
    line-height: 28px;
    text-align: right;
    font-weight: 600;
    color: #757575;
  }

  .parent_text_col {
  }

  .highlightClass {
    background-color: #f6d939;
    padding: 0;
  }

  .badge {
    &.badge-category {
      color: #585858;
      padding: 8px 10px 8px 10px;
      font-size: 13px;
      font-weight: 500;
      background-color: rgba(52, 140, 212, 0.07);
    }
    &.badge-attachment {
      color: #585858;
      padding: 3px;
      font-size: 13px;
      font-weight: 400;
      background-color: rgba(244, 244, 244, 1);
      .badge-attachment-row {
        margin-right: 0;
        margin-left: 0;
      }
    }
    .badge-file-name-col {
      &:hover {
        cursor: pointer;
      }
      max-width: 275px;
      display: table;
      padding-left: 6px;
      .badge-file-name {
        padding-top: 3px;
        padding-bottom: 2px;
        word-break: break-all;
        white-space: pre-line;
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

  .parent-note-exists-attachment-row {
    padding-top: 4px;
  }

  .onHoverAttachment {
    .showOnHoverAttachment {
      display: none;
    }
    .show-always {
      display: inline;
    }
    &:hover,
    &:focus-within {
      .showOnHoverAttachment {
        display: inline;
      }
    }
    .vertical-divider {
      padding-left: 0;
      max-width: 10px;
      margin-right: 14px;
      text-align: left;
      //padding: 0;
      span {
        &:hover {
          cursor: pointer;
        }
        border-left: 1px solid rgba(236, 237, 237, 1);
        //position:absolute;
        margin-left: 4px;
        padding-left: 4px;
        display: table-cell;
        vertical-align: middle;
        i {
          font-size: 16px;
          color: rgba(173, 173, 173, 1);
        }
      }
    }
  }

  .add-child-button {
    border: unset;
    background: white;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12);
    padding: 0;
    width: 20px;
    height: 20px;
    color: rgba(52, 140, 212, 1);
    &:hover {
      background-color: rgba(227, 227, 227, 1);
    }
    &.disabled {
      pointer-events: none;
      background-color: rgba(236, 237, 237, 1);
      i {
        color: rgba(173, 173, 173, 1) !important;
      }
    }
  }
}
