.projection-bar {
  .btn {

    i.fe-check {
      visibility: hidden;
      color: #0B1957;
    }
    min-width: 130px;

    &.active {

      i.fe-check {
        visibility: visible;
      }
    }
  }
}