//
// authentication.scss
//

.authentication-bg {
  background-color: $card-bg;
}

.authentication-logo {
  .logo-dark {
    display: $display-block;
  }
  .logo-light {
    display: $display-none;
  }
}

.authentication-bg-img {
  background-image: url("/assets/images/bg-countdown.jpg");
}

// authentication home icon
.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

.checkmark {
  width: 100px;
  margin: 0 auto;
  padding: 20px 0;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation: dash 2s ease-in-out;
  -webkit-animation: dash 2s ease-in-out;
}

.spin {
  animation: spin 2s;
  -webkit-animation: spin 2s;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
