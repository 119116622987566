.table-footer-buttons > div {
  display: inline-block;
  padding: 25px 0 0 0;
}

.table-footer-button {
  color: #585858;
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: center;
}

.table-footer-button i {
  color: #0B1957;
}

.table-footer-button:hover,
.table-footer-button:focus {
  text-decoration: none;
}

.table-footer-button:hover i,
.table-footer-button:focus i {
  text-decoration: none;
  color: #ffffff;
}

.table-footer-button:disabled {
  background: #F7F7F7;
  color: #585858;
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: center;
}

.table-footer-button:disabled i {
  color: #0B1957;
}
