/* Tooltpster */
.tooltipster-sidetip {
  .tooltipster-box {
    background-color: $primary;
    border: 2px solid $primary;
  }

  &.tooltipster-top {
    .tooltipster-arrow-border,
    .tooltipster-arrow-background {
      border-top-color: $primary;
    }
  }

  &.tooltipster-bottom {
    .tooltipster-arrow-border,
    .tooltipster-arrow-background {
      border-bottom-color: $primary;
    }
  }

  &.tooltipster-left {
    .tooltipster-arrow-border,
    .tooltipster-arrow-background {
      border-left-color: $primary;
    }
  }

  &.tooltipster-right {
    .tooltipster-arrow-border,
    .tooltipster-arrow-background {
      border-right-color: $primary;
    }
  }
}
