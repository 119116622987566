.export-progress-modal {
  text-align: center;
  font-weight: 500;

  .modal-header {
    border-bottom: 0;

    button {
      padding: 0 10px 0 0;
    }
  }

  .modal-body {
    padding-left: 40px;
    padding-right: 40px;
  }

  i {
    font-size: 45px;
    line-height: 70px;

    &.fa-spin {
      color: #0B1957;
    }
  }
}