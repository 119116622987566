@import "assets/scss/new_ui/colors";

.steps-progress__wrapper {
  border-bottom: 2px solid #ECEDED;
  padding-bottom: 30px;
  display: flex;
  overflow: hidden;

  .hidden {
    visibility: hidden;
  }

  nav {
    cursor: pointer;
    padding: 0 5px;
    display: flex;
    align-items: center;

    i {
      font-size: 1.5rem;
    }

    &:hover {
      color: #0B1957;
    }
  }

  &:hover .steps-progress__scroll-container {
    &::-webkit-scrollbar-thumb {
      background-color: #ADADAD;
      border: 4px solid transparent;
      border-radius: 9px;
      background-clip: content-box;
    }
  }

  .steps-progress {
    display: flex;
    transition-duration: 1500ms, 1500ms;
    transition: 1s;
    padding-bottom: 20px;

    &__scroll-container {
      position: relative;
      overflow-x: scroll;
      scroll-behavior: smooth;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: transparent;
      }
    }

    .step-bar {

      margin-right: 26px;
      min-width: 220px;

      &:last-child {
        margin-right: 0;
      }

      .step-progress {
        width: 100%;
        height: 6px;
        border-radius: 5px;
        margin-bottom: 11px;

        &.in_progress {
          background: $orange-60;
        }

        &.not_started {
          background: $grey-10;
        }

        &.finished {
          background: $green-60;
        }

        &.error {
          background: $red-60;
        }

        &.locked {
          background: $grey-10;
        }

        &.skipped {
          background: $blue-6;
        }
      }

      .step-number, .step-name {
        color: #ADADAD;
      }

      .step-actions {
        margin-top: .5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--indicator {
          height: 2px;
          width: 20px;
          background: #ECEDED;
        }

        .btn {
          padding: .1rem .8rem;
          border-radius: 5px;
        }
      }

      &--active {
        .step-number {
          color: #757575;
        }

        .step-name {
          font-weight: 600;
          color: #585858;
        }
      }
    }
  }
}