//
// dropify.scss
//

@font-face {
  font-family: "dropify";
  src: url("/assets/fonts/dropify.eot");
  src: url("/assets/fonts/dropify.eot#iefix") format("embedded-opentype"),
    url("/assets/fonts/dropify.woff") format("woff"), url("/assets/fonts/dropify.ttf") format("truetype"),
    url("/assets/fonts/dropify.svg#dropify") format("svg");
  font-weight: normal;
  font-style: normal;
}

.dropify-wrapper {
  border: 2px dashed $gray-300;
  background-color: transparent;
  border-radius: 6px;
  color: $gray-500;
  &:hover {
    background-image: linear-gradient(
      -45deg,
      $gray-300 25%,
      transparent 25%,
      transparent 50%,
      $gray-300 50%,
      $gray-300 75%,
      transparent 75%,
      transparent
    );
  }

  .dropify-preview {
    background-color: $gray-300;
  }
}
