.bank-connect-modal.modal {
  .modal-content {
    border-radius: 10px;
  }

  .modal-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-header {
    border-bottom: 0;

    .modal-title {
      color: #1B1B1B;
      font-size: 18px;
      flex: 1;
      text-align: center;
      font-weight: 600;
    }

    .modal-close {
      position: absolute;
      right: 10px;
      top: 0;
      background: #fff;
      border: 0;
      color: #ADADAD;
      padding: 10px;
      margin: 0;
      font-size: 20px;
      &:hover {
        color: #000;
      }

    }

    button {
      font-size: 30px;
    }
  }

  .modal-body {
    width: 450px;
    padding: 0;

    .notes {
      font-size: 12px;
      font-weight: 400;
      color: #757575;
      margin: 2rem 0;
      text-align: center;
    }
  }

  .redirect-flow, .decoupled-flow, .connect-failed {
    min-height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .redirect-flow {
    a {
      padding-left: 3.5rem;
      padding-right: 3.5rem;
      font-weight: 600;
    }
  }

  .decoupled-flow {
    font-size: 14px;

    .subtitle {
      font-weight: 400;
    }

    img {
      height: 300px;
      width: 300px;
    }

    .progress {
      width: 250px;
      height: 6px;
      margin-bottom: .5rem;

      .bg-soft-green {
        background: #6BD581;
      }
    }
  }

  .connect-failed {
    p {
      font-weight: 600;
    }

    p.error-detailed {
      font-weight: 500;
      line-height: 1.5;

    }

    button {
      border-radius: 5px;
      margin: 1rem 0;
      padding-left: 3.5rem;
      padding-right: 3.5rem;
    }
  }
}