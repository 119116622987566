.office-support-export-table {
  width: 100%;
  border-collapse: collapse;
  td {
    vertical-align: top;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
    padding-left: 12px;
    color: rgba(141, 141, 141, 1);

    .custom-control-label {
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.01em;
      text-align: left;
      color: rgba(141, 141, 141, 1);
    }
  }
  .main-section {
    background-color: rgba(52, 140, 212, 0.07);
    color: rgba(52, 140, 212, 1);

    .custom-control-label {
      color: rgba(52, 140, 212, 1);
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
    }
  }

  .sub-section {
    vertical-align: top;
    padding-left: 29px;

    .custom-control-label {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      color: rgba(120, 120, 120, 1);
    }
  }
}

.header-info {
  margin-top: 10px;
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: rgba(173, 173, 173, 1);
}

.custom-checkbox {
  .custom-control-input {
    border-radius: 2.44px;
    color: rgba(141, 141, 141, 1);
    &:disabled {
      &:checked ~ .custom-control-label::before,
      &:indeterminate ~ .custom-control-label::before {
        background-color: rgba(173, 173, 173, 1);
        border: 1px solid rgba(173, 173, 173, 1);
        color: rgba(141, 141, 141, 1);
      }
    }
  }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: rgba(141, 141, 141, 1);
  }
}

table.office-support-export-table.table-bordered th,
table.office-support-export-table.table-bordered td {
  border-color:  rgba(223, 226, 230, 1);
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;

}
#office-support-export {
  > div.table-filters-group {
    > section {
      > div {
        > label {
          color: rgba(115, 122, 129, 1);
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: -0.01em;
          text-align: left;
        }
      }
    }
  }
}
