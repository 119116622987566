@import "assets/scss/new_ui/colors.scss";
@import "assets/scss/new_ui/buttons.scss";

.bank-connection-item {
  width: 510px;
  border: 2px solid #0B1957;
  border-radius: 5px;
  padding: 20px;
  margin-right: 30px;
  margin-top: 30px;

  .connection_expire button {
    display: none;
    border-radius: 20px;
    height: 25px;
    font-size: 12px;
    line-height: 1;
    padding: 5px 20px;
    margin-left: 10px;
  }

  &.connection-warning {
    border-color: #FFAA30;

    .progress-bar {
      background: #FFAA30;
    }

    .connection_expire button {
      display: block;
      background: #FFAA30;
      border-color: #FFAA30;

      &:hover {
        background: #f28e00;
      }
    }
  }

  &.connection-danger {
    border-color: #F93636;

    .progress-bar {
      background: #F93636;
    }

    .connection_expire button {
      display: block;
      background: $red-80;
      border-color: $red-80;

      &:hover {
        border-color: $red-100;
        background: $red-100;
      }
    }
  }

  &.show-more {
    .connection_more__details {
      display: flex;
    }
  }

  .connection__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      color: #757575;
      min-width: 100px;
      background: #ECEDED;
      border-radius: 5px;
      border: 0;
      padding: 0 10px;
      height: 30px;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        background: #ddd;
      }

      i {
        font-weight: 600;
      }
    }

    .bank__title {
      color: #000;
      text-transform: initial;
      font-size: 30px;
      font-weight: 600;
      overflow-x: hidden;
    }
  }

  .connection_expire {
    display: flex;
    align-items: center;
    flex-direction: row;

    .progress {
      flex: 1;
      margin-right: 1rem;
      height: 6px;
    }
  }

  .connection_more {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__details {
      width: 100%;
      display: none;
      justify-content: space-between;
      padding-top: 1rem;

      .box {
        background: #F7F7F7;
        padding: 10px 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .box-label {
          font-size: 12px;
          color: #ADADAD;
        }

        .box-value {
          font-size: 14px;
        }
      }

    }

    .more-btn {
      margin-top: 1rem;
      cursor: pointer;
      font-size: 12px;
      font-weight: 400;

      i {
        color: #0B1957;
        font-weight: 600;
      }

    }
  }
}