$red-color-important: #F93636 !important;

.repeated-task-table {
  .table-filters-right,
  .table-filters-left {
    padding: 0;

    .container {
      margin: 0;
    }
  }

  .form-control,
  .status-filter,
  .filter-button {
    height: 47px;
  }

  .text-danger {
    padding-top: 6px;
    padding-left: 17px;
  }

  .react-datepicker__input-container {
    .form-control {
      border-radius: 0;
    }
  }

  .input-group.danger {
    .form-control,
    .input-group-text {
      border-top-color: $red-color-important;
      border-bottom-color: $red-color-important;
    }

    .input-group-text {
      border-left-color: $red-color-important;
    }
  }

  .asterisk {
    color: #3D898A;
  }
}