.status-list-overview-modal {
  .modal-header {
    border-bottom: 0;

    > div {
      padding-top: 1.5rem;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1, h2, h3 {
        margin: 0 0 4px 0;
      }

      h1 {
        color: #040B2C;
        font-size: 16px;
        font-weight: 600;
        line-height: 17px;
      }

      h2 {
        font-weight: 600;
        color: #456EE0;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      h3 {
        font-weight: 400;
        color: #7C8296;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }

  .table-responsive {
    min-height: 500px;
  }

  table {
    .select__menu-portal {
      //top: 0;
      //left: 0;
    }

    &.table.table-bordered th {
      border-bottom: 1px solid #f5f3f3;
    }

    tr.no-results td {
      vertical-align: top;
    }

    tr th {
      width: 250px;
      text-wrap: nowrap;
    }

    .event-col {
      width: 200px;

      &.disabled {
        background: #fff;
      }
    }
  }
}

.status-list-select.status-list-select__overview-modal {
  .overview-select-value {
    text-align: left;

    .status-tag {
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      border-radius: 4px;

      i {
        display: none;

      }

      .circle {
        display: none;
        width: 12px;
        height: 12px;
        background: #C670CA;
        border-radius: 6px;
      }

      &.gray {
        background: #F3F4F4;

        .circle {
          display: block;
          border: 2px dotted #ACACAC;
          background: #F3F4F4;
        }
      }

      &.purple {
        background: #C670CA1A;

        .circle {
          display: block;
        }
      }

      &.green {
        background: #6BD4811A;
        color: #60BD74;

        i {
          font-weight: 600;
          display: block;
        }

      }
    }
  }

  .menu-period-title {
    color: #ADADAD;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    padding: 10px 0 5px 14px;
  }
}

@media (min-width: 1300px) {
  .status-list-overview-modal.modal-xl {
    max-width: 1500px;
  }
}