@import "assets/scss/new_ui/colors";

.system-notification-bar {
  margin-left: -30px;
  margin-right: -30px;
  padding: 8px 16px;
  text-align: center;
  color: $blue-100;

  i {
    font-size: 16px;
    margin-right: .5rem;
  }

  a {
    font-weight: 600;
    text-decoration: underline;
  }

  &.bg-danger {
    background: $red-6 !important;
    border-bottom: 1px solid $red-8;
    i, a {
      color: $red-80;
    }
  }

  &.bg-warning {
    background: $orange-6 !important;
    border-bottom: 1px solid $blue-8;
    i, a {
      color: $orange-80;
    }

  }

  &.bg-info {
    background: $blue-6 !important;
    border-bottom: 1px solid $orange-8;
    i, a {
      color: $blue-30;
    }

  }

  &.bg-success {
    background: $green-6 !important;
    border-bottom: 1px solid $green-10;

    i, a {
      color: $green-60;
    }
  }

  i {
    margin-right: .5rem;
  }

  a {
    color: #fff;
    font-weight: 600;
    text-decoration: underline;
  }

  button {
    float: right;
    background: none;
    border: 0;
    font-weight: 600;
    color: $blue-40;

    &:hover {
      background: none;
      color: $blue-100;
    }
  }
}
