$blue-100: #040B2C;
$blue-80: #0B1957;
$blue-60: #0E2E86;
$blue-40: #22429A;
$blue-30: #5176DC;
$blue-20: #8FA8EC;
$blue-15: #B7C7EE;
$blue-10: #BCC6E0;
$blue-8: #DEE2ED;
$blue-6: #EBF0FC;
$blue-4: #F2F6FF;

$blue-gr2: #3F4A7C;

$red-100: #CF2525;
$red-80: #EA3939;
$red-60: #F47676;
$red-40: #FEA4A4;
$red-10: #E7C1C1;
$red-8: #EBDADA;
$red-6: #FFEDED;
$red-4: #FFF1F1;

$orange-100: #CC7E10;
$orange-80: #EEA032;
$orange-60: #EFA945;
$orange-40: #FFBE44;
$orange-10: #F3CD88;
$orange-8: #F4E2B2;
$orange-6: #FBEFCF;
$orange-4: #FEFAED;

$yellow-100: #CCAD00;
$yellow-80: #E0C114;
$yellow-60: #EFD543;
$yellow-40: #FCE545;
$yellow-20: #EDE486;
$yellow-10: #F5EFA4;
$yellow-6: #FAF7D7;

$purple-100: #791E7D;
$purple-80: #8D3291;
$purple-60: #B05AB4;
$purple-40: #B870BB;
$purple-20: #E2AFE4;
$purple-10: #ECD8ED;
$purple-6: #EFE8EF;
$purple-4: #F4EDF4;

$green-100: #38954C;
$green-80: #4CA960;
$green-60: #60BD74;
$green-40: #71CF85;
$green-20: #A5DFB1;
$green-10: #C0E8C9;
$green-6: #E4F2E7;
$green-4: #F2FDF5;

$purple-80: #8D3291;
$purple-60: #B05AB4;
$purple-40: #B870BB;
$purple-20: #E2AFE4;
$purple-10: #ECD8ED;
$purple-6: #EFE8EF;

$grey-100: #313144;
$grey-80: #4D4E66;
$grey-60: #7C8296;
$grey-40: #AAB0C6;
$grey-30: #C4C5CA;
$grey-10: #DBDBDB;
$grey-8: #EAEBEB;
$grey-6: #F7F7F7;


$dusty-blue: #5A90C4;
$beige: #C49681;
$pale-green: #E5F0E7;
$light-beige: #FFE7DC;
$sky-blue: #D2E8FF;
$light-rose: #EFE0F0;
$pale-red: #F6E3E3;

$draft: $grey-40;
$investigate: $purple-40;
$prelim: $orange-60;
$unpaid: $red-60;
$expired: $red-60;
$sentBank: $blue-20;
$paid: $green-40;

$text-black: #1b1b1b;