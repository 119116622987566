.sl-checklist {
  .controls {
    display: flex;
    justify-content: flex-end;

    .btn-outline-gray {
      color: #7C8296;
      box-shadow: 0 2px 10px 0 #0000001F;
      border-radius: 4px;
      padding: 8px;
      width: 32px;
      height: 32px;
      line-height: 1.3;

    }

    i.fe-check {
      color: #22429A;
    }

    i {
      font-weight: 600;
    }
  }

  .drag-wait {
    border: 1px dashed #fff;
    border-radius: 4px;
    margin: 5px 2px;

    &.drag-enter {
      border: 1px dashed #0000002F;
    }
  }


  .checklist-item {
    display: flex;
    padding: 5px 10px;


    .edit-form {
      width: 100%;
    }

    input[type="text"] {
      width: 100%;
    }

    .text {
      color: #313144;

      &.finished {
        text-decoration: line-through;
        color: #7C8296;
      }

      flex: 1;
    }

    &:hover {
      box-shadow: 0 2px 12px 0 #0000001F;

      .item-controls {
        display: flex;
      }
    }

    .item-controls {
      display: none;

      & > div {
        margin-left: 5px;
        cursor: pointer;

        &.move {
          cursor: move;
        }

        &:hover {
          i {
            font-weight: 600;
          }
        }
      }
    }
  }
}