.reinvoice_modal {
  .drafts {
    margin-bottom: 20px;
    max-height: 250px;
    width: 310px;
    max-width: 300px;
    overflow: auto;
    table {
      border: none;
      tr {
        border: none;
      }
      td {
        border: none;
        padding: 4px 1px 1px 1px !important;

        &.date {
          max-width: 90px;
          width: 90px;
        }
      }
      td:first-child {
        max-width: 9px;
        text-align: left;
        input {
          margin-top: 5px;
        }
        input[type="checkbox"] {
          accent-color: #0B1957;
          }
      }
      td:last-child {
        font-weight: 900;
      }
    }
  }
}
