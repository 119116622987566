.vat-filters {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .filters-left, .filters-right {
    display: flex;
    flex-direction: column;

    .react-select {
      width: 15rem;
    }

  }

  .filters-left {
    flex: 2;
    align-items: flex-start;

    .form-group {
      margin-bottom: 0;
    }

    .filters-row {
      display: flex;
      align-items: flex-start;
      margin-bottom: .75rem;

      & > div {
        margin-right: .75rem;
      }
    }
  }

  .filters-right {
    flex: 1;
    align-items: flex-end;

    .form-group {
      display: flex;
      margin-bottom: .5rem;

      label {
        width: 120px;
      }

      input {
        max-width: 150px;
      }
    }
  }
}

.vat-eu {
  height: 1px;

  .row-title {
    span {
      display: block;
      color: #000;
    }

    small {
      display: block;
      font-size: 11px;
      color: #ADADAD;
    }
    button {
      text-align: left;
    }
  }

  .reported-col {
    display: flex;
    justify-content: center;

    > div {
      margin-top: -10px;
    }
  }

  td.status-box {
    padding: 0;
    margin: 0;

    > div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .status-box {
    width: 90px;
    height: inherit;
    text-align: center;
    cursor: help;

    i.fe-info {
      position: absolute;
      right: 4px;
      top: 4px;
      font-size: 14px;
    }

    .sb {
      width: 15px;
      height: 15px;

      &.sb-gray {
        background: #DBDBDB;
      }

      &.sb-green {
        background: #6BD581;
      }

      &.sb-orange {
        background: #FFAA30;
      }

      &.sb-red {
        background: #F93636;
      }

      &.sb-black {
        height: 7px;
        width: 7px;
        background: #E9E9E9;
      }
    }

  }
}

.totals {
  .actions-vat_eu {
    border: 0;
  }
}