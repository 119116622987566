.status-list-welcome {
  display: flex;
  align-items: center;

  .card-body {
    margin-top: 3rem;
    min-height: 600px;
    width: 600px;

    h3, h4 {
      color: #0B1957;
      text-align: center;
    }

    h3 {
      font-size: 24px;
      line-height: 28px;

    }

    h4 {
      font-size: 16px;
      line-height: 24px;
    }

    form {
      margin-top: 3rem;

      > div {
        display: flex;
        justify-content: center;
      }

      button {
        margin-top: 23px;
        margin-left: 1rem;
      }
    }
  }
}

.activity-config {
  section {
    border: 1px solid #DFE2E6;
    margin-bottom: 1rem;

    .header {
      background: #fff;
      padding: 6px 12px;
      font-size: 14px;
      border-bottom: 1px solid #DFE2E6;
      line-height: 21px;
      font-weight: 700;
      text-transform: uppercase;
      color: #0B1957;
    }

    .body {
      background: #F8F8F8;
      padding: 12px;
    }

    .table {
      th {
        height: 50px;
        color: #7C8296;
      }

      tr td:first-child {
        font-weight: 500;

      }

      tr td:not(:first-child) {
        background: #456EE00F;
      }

      tr.disabled {
        td {
          background: #fff;
        }

        td:nth-child(n+3) {
          * {
            visibility: hidden;
          }
        }
      }

      td {

        .table-select__control {
          background: transparent;
        }

        .table-select__single-value {
          color: #313144;
        }

        .react-datepicker-wrapper {
          input {
            background: transparent;
            color: #313144;
          }
        }
        &.single-has-error {
          .react-datepicker-wrapper {
            background: transparent;
          }
        }
      }

      th, td {
        border-color: #E5E5E5;
        background: #fff;

      }

      td:not(:first-child) {
        //background: #456EE00F;
        &.disabled {
          //background: #fff;
        }
      }
    }
  }

  .form-submit {

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .btn-link {
      color: #585858;

      &:hover {
        color: #000;
      }
    }

    .btn[type="submit"] {
      border: 0;
      background: #22429A;
      color: #fff;
    }
  }

  .month-switch {
    .form-label {
      color: #7C8296;
      font-size: 13px;
      text-align: left;
      font-weight: 400;
    }

    .btn {
      color: #4D4E66;
      background: #fff;
      font-weight: 500;

    }


    .btn.active {
      color: #4D4E66;
      font-weight: 500;
      background: #456EE01F;
      border-color: #C8CEE4;

      i {
        visibility: visible;
        color: #22429A;
      }
    }
  }
}

.status-list-config-modal {
  .modal-content {
    border-radius: 10px;
  }

  .modal-header {
    border-bottom: 0;

    div {
      width: 100%;

      text-align: center;
    }

    h1 {
      margin-left: 50px;
      font-size: 16px;
      line-height: 17px;
      color: #040B2C;
      font-weight: 600;
    }

    h2 {
      margin-left: 50px;
      font-size: 11px;
      line-height: 18px;
      letter-spacing: 0.1rem;
      color: #7C8296;
      font-weight: 600;
    }
  }

  .modal-body {
    padding: 0 40px;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      white-space: pre-wrap;
    }

    label {
      font-size: 13px;
      margin-bottom: 0;
      font-weight: 400;
    }

    textarea {
      border-color: #456EE099 !important;
      white-space: pre-wrap;
    }
  }

  .modal-footer {
    border-top: 0;
    padding: 20px 40px;

    button {
      margin: 0;
    }
  }

  &.deactivate-form {
    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      .form {
        width: 55%;
      }
    }
  }

  #activationForm {

    .alert-warning {
      display: flex;
      font-size: 14px;
      font-weight: 400;

      i {
        margin-right: .5rem;
        color: #DD8F21;
      }

      background: #FFAA301A;
      border: 1px solid #FFAA30;
      color: #313144;
    }

    .modal-footer {
      button.btn-link {
        color: #7C8296;

        &:hover {
          color: #4D4E66;
        }
      }
      &:not(.has-warning){
        button[type="submit"] {
          border-color: #22429A;
          background: #22429A;
          color: #fff;
        }
      }
      &.has-warning {
        button[type="submit"] {
          background: #fff;
          border-color: #F93636;
          color: #4D4E66;

          &:hover {
            background: #F93636;
            color: #fff;
          }
        }
      }
    }
  }
}