.bank-mapping-modal.modal {
  .modal-content {
    border-radius: 10px;
    min-height: 400px;
  }

  .modal-header {
    display: flex;
    justify-content: center;

    .modal-title {
      font-size: 18px;
      font-weight: 600;

    }
  }

  .modal-header {
    border-bottom: 0;
  }

  .table {
    font-size: 13px;

    th {
      font-size: 12px;
      font-weight: 500;
      color: #ADADAD;
    }

    td {
      background: #F7F7F7;
      border-bottom: 4px solid #fff;
      border-right: 2px solid #fff;
    }

    th, td {
      border-top: 0;
      padding: 5px 10px 5px 20px
    }
    td.select {
      width: 150px;
    }
    .table-select {
      padding-left: 20px;

      .table-select__control {
        background: #F7F7F7;
        margin: 0;
        padding: 0;
        font-size: 13px;
      }

      .table-select__value-container {
        padding: 0;
      }

      .table-select__input-container {
        padding: 0;
        margin: 0;
      }
    }

  }

  .modal-actions {
    display: flex;
    justify-content: center;
    flex-grow: initial;

    button[type="submit"] {
      margin-left: 1rem;
      border-radius: 5px;
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
}