#salaryForm {
  table {
    td,
    th {
      vertical-align: middle;
    }

    tbody {
      tr:not(.selected) {
        background: #fafafa;
      }

      td {
        margin: 0;
        padding: 0;
        width: 120px;

        input {
          border: 0;
        }
      }

      td:first-child {
        padding: 5px;
      }
    }

    tfoot {
      td {
        text-align: right;
      }
    }
  }
}

#ciForm,
.discount-modal,
.rot-rut-modal,
#time-tracker-settings {
  table {
    thead {
      th {
        &:nth-child(2) {
          width: 20%;
        }

        &:nth-child(3),
        &:nth-child(4) {
          width: 10%;
        }

        &:nth-child(5) {
          width: 30%;
        }

        &:nth-child(6) {
          width: 120px;
        }
      }
    }

    tbody {
      td {
        padding: 0;
        margin: 0;
        vertical-align: middle;

        &.disabled {
          background: $gray-100;
        }

        input,
        span {
          padding-left: 0.85rem;
          padding-right: 0.85rem;
        }

        input {
          &:disabled {
            background: $gray-100;
          }

          border: 0 !important;
          border-radius: 0;
        }

        .table-select--is-disabled {
          .table-select__control {
            background: $gray-100;
            border-radius: 0;
          }

          .table-select__value-container {
            div[class^="css"] {
              padding: 0 !important;
              margin: 0 !important;
            }
          }
        }
      }

      tr:last-child {
        border-bottom: 2px solid $gray-500;
      }
    }

    tfoot {
      td {
        padding: 0.6rem 0.85rem;
      }
    }
  }
}

#ciForm {
  .customer-select {
    width: 80%;

    & > div {
      border-radius: 0;
      border-color: #6c757d;
    }
  }

  .product-select > div {
    border-radius: 0;
    border-color: #6c757d;
  }

  .records-tool {
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
    }

    .product-select {
      min-width: 250px;
      .select__control {
        height: 38px;
      }
    }

    button.text-row {
      border-radius: 0;
      height: 38px;
      border-left: 0;
    }
  }

  table.records {
    tfoot {
      tr {
        td:first-child,
        td:last-child {
          border: 0 !important;
        }
      }
    }
  }

  .dropzone-wrapper.file-selected {
    height: 400px;
  }
}

.specifications {
  table {
    th,
    td {
      vertical-align: middle;
      padding: 0.3rem;
    }

    tbody td {
      padding: 0.1rem;
    }

    td input {
      border: 0;
      width: 100%;

      &[type="number"] {
        text-align: right;
      }
    }

    tr td.has-errors {
      padding: 5px;
      background: rgba(255, 0, 0, 0.1);

      ul {
        padding-inline-start: 25px;
        margin: 0;

        li {
          line-height: 1;
        }
      }
    }

    .td-actions {
      width: 1%;
      padding-right: 0.5rem;
      padding-left: 0.5rem;

      .upload-btn {
        display: inline-block;
      }

      div,
      button:not(:last-child),
      a:not(:last-child) {
        margin-right: 4px;
      }

      white-space: nowrap;
      vertical-align: middle;
      text-align: left;
    }

    tr.error {
      td {
        padding: 0.5rem;
      }
    }

    tfoot {
      text-align: right;

      .sum-amount {
        padding-right: 15px;
      }
    }
  }
}

#cashflow-table {
  position: relative;
  overflow: auto;
  white-space: nowrap;

  &.cashflow-sticky-header {
    overflow: auto;
    width: 100%;
    height: 800px;
    background: white;

    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      border: 0;

      thead th {
        position: sticky;
        top: 0;
        background: white;
      }

      td,
      th {
        width: max-content;
        border: 0;
        box-shadow: inset 0 0 1px #444;
      }

      tbody tr td:first-child {
        /* need to do the ellipsis overflow manually to keep the column box-shadow hack */
        background: white;
      }

      tr td:first-child:after,
      tr th:first-child::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: -15px;
        width: 15px;
      }

      th:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        min-width: 400px;
        box-shadow: inset 0 0 1px #444, 1px 0 0 0 $primary;
      }

      thead th {
        z-index: 1;
        box-shadow: inset 0 0 1px #444, 0 1px 0 0 $primary;
      }

      thead th:first-child {
        z-index: 3;
        background: #fff;
        box-shadow: inset 0 0 1px #444, 1px 0 0 0 $primary, 0 1px 0 0 $primary;
      }

      tbody th:first-child {
        z-index: 2;
        background: #fff;
      }
    }
  }

  table {
    //border-left: 0 !important;
    vertical-align: center;

    td {
      width: 120px;
      min-width: 120px;
    }

    td,
    th:not(:first-child) {
      text-align: center;
    }

    .cash-in,
    .cash-in th {
      background: #ebfbe6 !important;
    }

    .cash-out,
    .cash-out th {
      background: #fff0f0 !important;
    }

    .ex-off th,
    .ex-on th {
      cursor: pointer;
    }

    .ex-off th:not(.ci-simulation):first-child::before,
    .ex-off th.ci-simulation div div:first-child::before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f067";
      margin-right: 5px;
    }

    .ex-on th:not(.ci-simulation):first-child::before,
    .ex-on th.ci-simulation div div:first-child::before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f068";
      margin-right: 5px;
    }

    .payments-from-customers.ex-on th:first-child,
    .addon.ex-on th:first-child,
    .salaries.ex-on th:first-child,
    .si.ex-on th:first-child {
      width: 700px;
      min-width: 700px;
      max-width: 700px;
    }

    .nd2 th {
      padding-left: 1.2rem;
    }

    .nd3 th {
      padding-left: 1.8rem;
    }

    .t-wrap {
      &.t-header > th {
        background: $gray-100;
      }

      .t-row {
        display: flex;

        .t-col {
          flex: 1;
        }
      }

      .td-addon-actions {
        right: 0.3rem;
        position: absolute;
        top: 0.6rem;

        & > button {
          margin-right: 0.2rem;
        }
      }

      .t-si {
        select {
          padding: 0;
          margin: 0;
          border: 0;
          height: 100%;
        }
      }

      &.nos > th::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: $secondary;
        width: 0.5rem;
        height: 100%;
      }

      &.lts > th::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: $primary;
        width: 0.5rem;
        height: 100%;
      }

      &.sis > th::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: $success;
        width: 0.5rem;
        height: 100%;
      }
    }

    .ci-simulation {
      padding: 0;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
          display: flex;
        }
      }

      select {
        border: 0;
        border-radius: 0;
        width: auto;
        background: #fefecc;
        min-height: 43px;
        border-left: 1px solid #ccc;
        font-size: 0.75rem;
        padding: 0 0 0 5px;
      }
    }
  }
}

#silverback-form {
  .toolbar {
    display: flex;
    justify-content: space-between;
  }

  table {
    td {
      padding: 0rem;

      input {
        border: 0;
      }

      .checkbox {
        text-align: center;
      }
    }

    tbody {
      th {
        padding: 0 0.5rem 0 1rem;

        &.main {
          background: #fefecc;
        }
      }
    }
  }
}

#time-tracker-settings {
  table {
    .disabled.plain {
      padding-left: 0.85rem;
    }

    td.td-selectable {
      //position: relative;
      //white-space: nowrap;
      width: 1%;
      padding-left: 0.4rem;
      padding-right: 0.4rem;
      //padding:0;
      //margin:0;
      //text-align: center;
      //vertical-align: middle;
    }

    input {
      border: 0;
      padding: 0;
      margin: 0;
    }
  }
}

#time-tracker {
  .content-page {
    padding-top: 1.5rem;
  }

  .footer {
    left: 0;
  }

  .hacked-checkbox label {
    margin-bottom: 10px;
    margin-left: 10px;
  }

  #tt-calendar {
    table.sticky-header thead th {
      z-index: 99;
    }
    .day-switcher {
      vertical-align: middle;
      display: flex;
      align-items: center;
      font-size: 1rem;

      .now {
        font-size: 1rem;
        padding: 0;
        width: 220px;
      }
    }

    .table-wrapper {
      position: relative;

      table {
        table-layout: fixed;
        min-height: 500px;
        border: 0;

        thead tr th {
          height: 50px;
        }

        th:first-child,
        td:first-child {
          border-left: 0;
        }

        th:last-child,
        td:last-child {
          border-right: 0;
        }

        thead th {
          text-transform: uppercase;
        }

        td {
          padding: 0.4rem;
          vertical-align: top;

          .task {
            position: relative;
            border: 1.5px solid #dedede;
            border-radius: 6px;
            width: 100%;
            margin-bottom: 0.5rem;
            padding: 0.2rem 0.5rem;
            color: #585858;

            font-size: 0.75rem;

            .duration {
              color: #9298a7;
            }

            .company {
              display: flex;
              align-items: center;
              column-gap: 4px;

              font-weight: 500;

              padding-bottom: 2px;
              border-bottom: 1px solid #eceded;
              margin-bottom: 3px;

              overflow: hidden;
            }

            .suitcase-color {
              color: #aed1ee;
            }

            &.task-overdue {
              border-color: #ff7f7f !important;

              .initials-author-background {
                background-color: #ff7f7f !important;
              }
            }

            &.task-custom {
              border-color: #65cccc;

              .initials-author-background {
                background-color: #65cccc;
              }
            }

            &.task-permanent,
            &.task-internal {
              border-color: $dark;
            }

            &.task-company {
              border-color: $dark;

              .initials-author-background {
                background-color: $dark;
              }
            }

            &.task-finished {
              border-color: #8ee4a0;

              .initials-author-background {
                background-color: #8ee4a0;
              }
            }

            &.task-playing {
              border-color: #0B1957;

              .initials-author-background {
                background-color: #0B1957;
              }
            }

            .t-row {
              display: flex;
              justify-content: space-between;
            }

            .title {
              display: flex;
              align-items: center;
              font-weight: 500;
              font-size: 0.875rem;
            }

            .comment {
              margin-left: 5px;
              color: #adadad;
              font-size: 12px;
            }

            .initials-hover {
              align-self: flex-start;
            }

            .author-tooltip {
              position: absolute;
              width: max-content;
              height: 22px;
              z-index: 10;
              border-radius: 20px;
              top: -10px;
              left: 90%;

              font-size: 0.75rem;
              font-weight: 400;

              padding: 2px 9px;
              color: white;
              background: #757575;
              opacity: 0;
              visibility: hidden;
              transition: opacity 0.2s;
            }

            .initials-author {
              position: absolute;
              right: 10px;
              width: 24px;
              height: 24px;

              z-index: 10;

              display: flex;
              align-items: center;
              justify-content: center;

              cursor: default;

              font-size: 12px;
              font-weight: 400;
              background-color: #aed1ee;
              color: white;

              border-radius: 100%;
            }

            .initials-hover:hover {
              .author-tooltip {
                opacity: 1;
                visibility: visible;
              }
            }

            .task-overlay {
              display: none;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba(255, 255, 255, 0.5);
              text-align: center;
              border-radius: 6px;

              .task-overlay-wrapper {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 0.75rem;
              }

              .btn-play,
              .btn-stop,
              .btn-finish,
              .btn-stop,
              .btn-revert {
                cursor: pointer;
                color: #fff;
                width: 36px;
                height: 36px;
                border-radius: 18px;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                  font-size: 14px;
                }
              }

              .btn-revert {
                background: #f5b150;

                &:hover {
                  background: #f19613;
                }
              }

              .btn-play {
                background: #0B1957;

                i {
                  padding-left: 2px;
                }

                &:hover {
                  background: #2570ae;
                }
              }

              .btn-finish {
                background: #8ee4a0;

                &:hover {
                  background: #52d66e;
                }
              }

              .btn-stop {
                background: #c3c3c3;

                &:hover {
                  background: #9c9c9c;
                }
              }

              .btn-group {
                top: 20%;
                //left: 50%;
                //transform: translate(-50%, -50%);
              }
            }

            &:hover {
              .task-overlay {
                display: block;
              }
            }
          }
        }
      }
    }

    &.by-company {
      table td {
        .task {
          margin-left: 0.5rem;
          margin-bottom: 0.5rem;
          display: inline-grid;
          min-height: 67px;
          min-width: 220px;
          width: calc(100% / 7 - 0.75rem);
        }
      }
    }
  }
}

#company-manage-table {
  .react-datepicker-wrapper {
    width: 100%;
    max-width: 200px;
  }

  .sticky-header:not(.day-to-day) {
    tr:first-child {
      th {
        box-shadow: inset 0 -1px 0 #dee2e6;
      }
    }

    .sticky2 th {
      top: 114px;
      box-shadow: inset 0 -2px 0 #78c350;
    }
  }

  .form-control:disabled {
    background: #d1dee5;
  }
}

.inline-comments {
  //font-size: 90%;
  margin-top: 1rem;
  //padding: 1rem 0 0 1rem;
  .comments-item {
    padding: 0.5rem;

    .text-muted {
      margin-bottom: 0;
    }
  }
}

#fortnox {
  .card {
    min-height: 200px;
  }

  section.fortnox__sie {
    margin-top: 1rem;
    margin-left: 0.375rem;
    padding: 0.5rem;
    border: 1px solid $warning;
  }
}

.psd2 {
  .bank-list {
    .item {
      display: flex;

      .item-logo {
        width: 15.5rem;
        height: 4.8rem;
        align-self: center;

        img {
          object-fit: cover;
          object-position: left;
          width: 100%;
          height: 100%;
          border-radius: 0.375rem;
        }
      }

      .item-name {
        align-self: center;
        margin-left: 2rem;
        font-size: 1.2rem;
        color: #888;
      }
    }
  }
}

table.global-user-perm-table {
  thead tr {
    th {
      background: #fefefe;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.05) !important;
    }
  }

  tr {
    &:hover {
      box-shadow: none !important;
    }

    td {
      background: #fff;

      &.agency {
        background: #fafafa;
      }
    }
  }

  tfoot {
    border-top: 10px solid #dee2e6;
  }
}

table#scheduleTemplates.table {
  tr:hover {
    transition: none !important;
    background-color: inherit;
    box-shadow: none;
  }
}

.content-vertical-center {
  display: flex;
  align-items: center;
}

.schedule-exceptions {
  table {
    td,
    th {
      border: 0;
    }

    tr:hover {
      background-color: transparent !important;
      box-shadow: none !important;
    }

    .is-invalid {
      border-color: #f93636 !important;
      border-width: 1px !important;
    }
  }

  td {
    padding: 2px;
  }
}

.payment-basket-errors {
  .alert {
    border-radius: 0;
  }
}

.status-dot {
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 5px;
  margin-left: 6px;
  margin-bottom: 3px;

  &.red {
    background-color: $softRed;
  }

  &.orange {
    background-color: $softOrange;
  }

  &.grey {
    background-color: $disabledGrey;
  }

  &.hidden {
    background-color: transparent;
  }
}

.up-to-date-container {
  margin-top: -10px;
  margin-bottom: 10px;
  color: $darkGrey;

  .up-to-date-toogle {
    height: 20px;
    width: 32.5px;
    border: 1px solid $darkGrey;
    border-radius: 62px;
    background-color: transparent;
    text-align: left;
    padding: 1.5px;
    margin-left: 8px;

    &:focus,
    &:active,
    &:hover {
      background: transparent !important;
    }

    .circle {
      background-color: $darkGrey;
      color: $white;
      display: inline-block;
      text-align: center;
      border-radius: 15px;
      height: 15px;
      width: 15px;
    }

    &.checked {
      background-color: $darkerSoftGreen;
      border: 1px solid $darkerSoftGreen;
      text-align: right;

      &:focus,
      &:active,
      &:hover {
        background: $softGreen !important;
        border: 1px solid $softGreen;
      }

      .circle {
        background-color: $white;
        color: $darkerSoftGreen;
      }
    }
  }
}

.header-info {
  margin-top: -10px;
  color: $disabledBtnGrey;
  font-size: 12px;
}

.list-indicators-tooltip {
  .arrow {
    &::after,
    &::before {
      display: none;
    }
  }

  .tooltip-inner {
    text-align: left;
    width: auto;
    max-width: none;
    font-size: 12px;
    line-height: 18px;
    padding: 10px 11px 10px 11px;
    border-radius: 4px;

    p {
      margin: 1px;
    }

    .status-icon {
      margin-right: 5px;
      width: 20px;
      height: 20px;

      &.red {
        color: $softRed;
      }

      &.orange {
        color: $softOrange;
      }

      &.grey {
        color: $disabledGrey;
      }
    }
  }
}