//
// bootstrap-select.scss
//

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100% !important;
}

.bootstrap-select {
  .dropdown-toggle {
    &:after {
      content: "\F140";
      display: inline-block;
      font-family: "Material Design Icons";
    }
    &:focus {
      outline: none !important;
      outline-offset: 0;
    }
  }
  a {
    outline: none !important;
  }
  .inner {
    overflow-y: inherit !important;
  }
  > .btn-pink {
    &.bs-placeholder {
      color: $white !important;
    }
  }
}
