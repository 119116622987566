@import "assets/scss/new_ui/colors";

#iso-basket-table {
  .expanded > td {
    background: $blue-4;
  }


  .iso-payment-preview-list {
    margin-left: 50px;
    font-size: 13px;

    table {
      width: auto;
      margin-bottom: 0;

      tr {
        background: transparent;

        &:hover {
          box-shadow: none;
        }
      }

      td, th {
        min-width: 250px;
        border: 0;
        padding: 0;
      }

      td {
        font-weight: 500;

        .fe-x {
          color: $red-80;
        }

        .fe-check-circle {
          color: $green-80;
        }

        .fe-send {
          color: $purple-60;
        }
      }

      th {
        color: $grey-60;
        font-weight: 400;
      }
    }
  }
}

.restore-tooltip {
  text-align: left;
  font-weight: 400;
  color: #fff;

  .date {
    color: $grey-30;

  }
}