@import "colors";

:root {
  --toastify-icon-color-error: #EA3939; // $red-80
  --toastify-icon-color-warning: #EEA032; // $orange-80
  --toastify-icon-color-success: #4CA960; // $green-80
  --toastify-icon-color-info: #5176DC; // $blue-30
  --toastify-toast-min-height: 50px;
}

.Toastify__toast-container {
  margin-top: -10px;
  .Toastify__toast {
    color: $text-black;

    border-radius: 4px;
    padding: 10px 12px;
    box-shadow: 0 4px 8px 0 #0000001F;
    font-weight: 500;

    .Toastify__close-button {
      color: $grey-60;
      align-self: start;
      opacity: 1;

      svg {
        transform: scale(1.3);
        margin-top: 4px;
      }
    }

    .Toastify__toast-icon {
      align-self: start;
    }

    .Toastify__toast-body {
      padding: 0;
      white-space: pre-line;

    }


    &.Toastify__toast--success {
      background: $green-4;
      border: 1px solid $green-10;
    }

    &.Toastify__toast--error {
      background: $red-4;
      border: 1px solid $red-8;
    }

    &.Toastify__toast--warning {
      background: $orange-4;
      border: 1px solid $orange-8;
    }

    &.Toastify__toast--info {
      background: $blue-4;
      border: 1px solid $blue-10;
    }
  }
}