@import "assets/scss/new_ui/colors.scss";

.employee-initials-badge-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;

  .initials-badge {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: white;
    border: 1px solid $grey-40;
    color: $blue-80;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      background: #f0f0f0;
    }

    &.approved {
      border: 1px solid $green-40;
      background: rgba(107, 212, 129, 0.12);
      color: $green-80;
    }
    &.denied {
      border: 1px solid $purple-80;
      background: $purple-6;
    }
    &.next {
      border: none;
      background: $grey-6;
      color: $grey-40;
    }
    &.for-approval {
      border: 1px dashed $grey-10;
      background: $orange-4;
      color: $blue-80;
    }

    .initials-text {
      display: inline-block;
    }

    .trash-icon {
      display: none;
      position: absolute;
      font-size: 16px;
      color: $red-60;

      &:hover {
        color: $red-80;
      }
    }
  }

  &.form-mode:hover {
    .initials-text {
      display: none;
    }
    .trash-icon {
      display: block;
    }
  }

  &.preview-mode {
  }
}

.approvers-full-name-badges {
  &.badge,
  .badge {
    &:hover {
      cursor: default;
    }
    color: $blue-80;
    padding: 7px;
    margin: 3px;
    border: 1px solid $grey-40;
    &.approved {
      border: 1px solid $green-40;
      background: rgba(107, 212, 129, 0.12);
    }
    &.denied {
      border: 1px solid $purple-80;
      background: $purple-6;
    }
    &.next {
      border: none !important;
      background: $grey-6;
      color: $grey-40 !important;
    }
  }
}

.employee-names-badge-wrapper {
  display: inline-flex;
  align-items: center;
  text-wrap: nowrap;
  padding: 3px;
  border: 1px solid $grey-40;
  border-radius: 30px;
  background-color: #ffffff;
  margin-left: 5px;

  .employee-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $blue-40;

    &:last-child {
      margin-right: 5px;
    }
    &:first-child {
      margin-left: 5px;
    }
  }

  .separator {
    font-size: 14px;
    font-weight: 600;
    color: $grey-40;
    margin-right: 8px;
    margin-left: 8px;
  }
}
