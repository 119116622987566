.bank-connections {
  height: 100%;
  width: 100%;
  background: #fff;
  margin-top: 94px;
  padding: 100px 1rem 1rem 1rem;
  min-height: 86vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 1rem;
    width: 1080px
  }

  .bank-connection-items {
    display: flex;
    background-clip: padding-box;
  }

  &__empty {
    h1 {
      width: auto;
    }

    .bank-connection-items {
      flex-direction: row;
    }
  }
}

.new-connection-box__container {
  width: 510px;
  margin-top: 30px;
  margin-right: 30px;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .new-connection-box {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 393px;
    height: 218px;
    background: #F7F7F7;
    border-radius: 5px;
    color: #757575;

    &:hover {
      border: 1px solid #ccc;
    }

    span {
      //display: block;
      font-size: 16px;
    }

    i {
      //display: block;
      font-size: 40px;
    }
  }
}