//
// horizontal-nav.scss
//

// Container width
@include media-breakpoint-up(xl) {
  body[data-layout="horizontal"] {
    .container-fluid {
      max-width: 90%;
    }
  }
}

body[data-layout="horizontal"] {
  .navbar-custom {
    padding: 0 10px;
    background-color: $bg-topbar-dark;
    box-shadow: none;
  }

  .logo-box {
    width: auto !important;
    padding-right: 1.5rem;
  }

  .logo-light {
    display: block;
  }

  .logo-dark {
    display: none;
  }

  #topnav {
    .navbar-toggle span {
      color: $gray-700;
    }
  }

  .content-page {
    margin-left: 0 !important;
    padding-top: 60px;
  }

  .footer {
    left: 0 !important;
  }

  @include media-breakpoint-down(sm) {
    .logo-box {
      display: block;
    }

    .logo span {
      &.logo-lg {
        display: none;
      }
      &.logo-sm {
        display: block;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .content-page {
      padding-top: 0;
    }
  }
}

// Top header
#topnav {
  background: $bg-topbar-light;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1001;
  box-shadow: $box-shadow;
  padding: 0 12px;

  // Menu item arrow
  .arrow-down {
    display: inline-block;

    &:after {
      border-color: initial;
      border-style: solid;
      border-width: 0 0 1px 1px;
      content: "";
      height: 0.4em;
      display: inline-block;
      right: 5px;
      top: 50%;
      margin-left: 5px;
      transform: rotate(-45deg) translateY(-50%);
      transform-origin: top;
      transition: all 0.3s ease-out;
      width: 0.4em;
    }
  }

  // Navbar Toggle
  .navbar-toggle {
    border: 0;
    position: relative;
    padding: 0;
    margin: 0;
    cursor: pointer;

    .lines {
      width: 25px;
      display: block;
      position: relative;
      margin: 15px 20px 0 20px;
      padding-top: 13px;
      height: 44px;
      transition: all 0.5s ease;
    }
    span {
      height: 2px;
      width: 100%;
      background-color: rgba($white, 0.6);
      display: block;
      margin-bottom: 5px;
      transition: -webkit-transform 0.5s ease;
      transition: transform 0.5s ease;
    }

    &.open {
      span {
        position: absolute;
        &:first-child {
          top: 18px;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          visibility: hidden;
        }
        &:last-child {
          width: 100%;
          top: 18px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }
  }

  // Active Menu color
  .has-submenu {
    &.active {
      > a {
        color: $primary;
      }
      .submenu {
        li.active > a {
          color: $primary;
        }
      }
    }
  }

  .topbar-menu {
    margin-top: $topbar-height;
  }
}

.navigation-menu {
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    float: left;
    position: relative;

    > a {
      display: block;
      color: rgba($dark, 0.8);
      font-size: 13px;
      transition: all 0.5s ease;
      line-height: 20px;
      padding: 20px;
      position: relative;

      i {
        font-size: 15px;
        margin-right: 8px;
      }

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
}

/*
Responsive Menu
*/

@media (min-width: 992px) {
  .navigation-menu {
    > li {
      > a {
        &:hover,
        &:focus,
        &:active {
          color: $primary;
        }
      }
      &:hover {
        a {
          color: $primary;
        }
      }

      &:first-of-type > a {
        padding-left: 0;
      }

      &.last-elements {
        .submenu {
          left: auto;
          right: 0;

          > li.has-submenu {
            .submenu {
              left: auto;
              right: 100%;
              margin-left: 0;
              margin-right: 10px;
            }
          }
        }
      }
      > ul {
        > li {
          &.has-submenu {
            &:active,
            &:hover {
              > a {
                color: $primary;
              }
            }
          }
        }
      }

      .submenu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        padding: 10px 0;
        list-style: none;
        min-width: 200px;
        text-align: left;
        visibility: hidden;
        opacity: 0;
        margin-top: 10px;
        border-radius: 4px;
        transition: all 0.2s ease;
        background-color: $card-bg;
        box-shadow: $box-shadow;

        li {
          position: relative;

          ul {
            list-style: none;
            padding-left: 0;
            margin: 0;
          }

          a {
            display: block;
            padding: 9px 25px;
            clear: both;
            white-space: nowrap;
            color: rgba($dark, 0.7);

            &:hover {
              color: $primary;
            }
          }

          &.has-submenu {
            > a {
              .arrow-down {
                &:after {
                  right: 20px !important;
                  transform: rotate(-135deg) translateY(-50%) !important;
                  position: absolute;
                }
              }
            }
          }

          .submenu {
            left: 100%;
            top: 0;
            margin-left: 10px;
            margin-top: -1px;
          }
        }

        &.megamenu {
          white-space: nowrap;
          width: auto;

          > li {
            overflow: hidden;
            width: 200px;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }
  }

  .navbar-toggle {
    display: none !important;
  }

  #navigation {
    display: block !important;
  }
}

@media (min-width: 768px) {
  .navigation-menu {
    > li {
      &.has-submenu {
        &:hover {
          > .submenu {
            visibility: visible;
            opacity: 1;
            margin-top: 0;

            > li {
              &.has-submenu {
                &:hover {
                  > .submenu {
                    visibility: visible;
                    opacity: 1;
                    margin-left: 0px;
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
        &.last-elements {
          > .submenu {
            &:before {
              right: 11px;
              left: auto;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #topnav {
    .navigation-menu {
      float: none;
      max-height: 400px;
      text-align: left;

      > li {
        display: block;
        float: none;

        > a {
          color: $menu-item;
          padding: 15px;
          i {
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 0;
            vertical-align: inherit;
          }

          &:after {
            position: absolute;
            right: 15px;
          }
        }

        .submenu {
          display: none;
          list-style: none;
          padding-left: 20px;
          margin: 0;

          li {
            a {
              display: block;
              position: relative;
              padding: 7px 20px;
              color: $menu-item;

              &:hover {
                color: $primary;
              }
            }
          }
          &.open {
            display: block;
          }

          .submenu {
            display: none;
            list-style: none;

            &.open {
              display: block;
            }
          }
          &.megamenu {
            > li {
              > ul {
                list-style: none;
                padding-left: 0;
              }
            }
          }
        }
        &.has-submenu.open > a {
          color: $primary;
        }
      }
    }

    .has-submenu {
      &.active {
        a {
          color: $primary;
        }
        .submenu {
          li.active > a {
            color: $primary;
          }
        }
      }
    }
    .arrow-down {
      &:after {
        right: 15px;
        position: absolute;
      }
    }
    .navbar-header {
      float: left;
    }
  }
  #navigation {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    display: none;
    height: auto;
    padding-bottom: 0;
    overflow: auto;
    box-shadow: $box-shadow;
    background-color: $light;

    &.open {
      display: block;
      overflow-y: auto;
    }
  }
}

@media (max-height: 375px) {
  .navigation-menu {
    max-height: 240px !important;
  }
}

body[data-layout="horizontal"] {
  // Light Menubar
  &[data-topbar="light"] {
    .logo-light {
      display: $display-none;
    }

    .logo-dark {
      display: $display-block;
    }

    #topnav {
      background: $bg-topbar-dark;
      box-shadow: $box-shadow;

      .navbar-custom {
        background: $bg-topbar-light;
      }
    }
    @include media-breakpoint-up(lg) {
      #topnav {
        // Active Menu color
        .has-submenu {
          &.active {
            > a {
              color: $white;
            }
            .submenu {
              li.active > a {
                color: $primary;
              }
            }
          }
        }
      }

      .navigation-menu {
        > li {
          > a {
            color: rgba($white, 0.7);
          }
        }
      }
    }
  }

  // Center Menu

  &[data-menu-align="center"] {
    @include media-breakpoint-up(lg) {
      #topnav {
        .navigation-menu {
          text-align: center;

          > li {
            display: inline-block;
            float: none;

            > a {
              padding: 20px;

              i {
                display: block;
                margin: 0 0 5px 0;
                float: none;
                text-align: center;
              }

              .arrow-down {
                display: none;
                margin: 0 0 5px 0;
              }
            }
          }
        }
      }
      .content-page {
        padding-top: 80px;
      }
    }
  }

  // Unsticky Header
  &.unsticky-header {
    @include media-breakpoint-up(lg) {
      #topnav,
      .navbar-custom {
        position: absolute;
      }
    }
  }
}
