@import "assets/scss/new_ui/colors";

.active-tasks {
  background: #fff;
  padding: 0 .8rem .8rem .95rem;
  border-bottom: 1px solid $grey-8;

  .active-task {
    border: 1px solid #6C738C;
    border-radius: 3px;
    padding: .3rem;
    padding-bottom: 0;

    &--normal {
      border-color: #0B1957;
    }

    &__title {
      font-size: .75rem;
      font-weight: 600;
      color: #6C738C;
    }

    &__company {
      margin-top: -4px;
      font-size: .7rem;
      color: #6C738C;
    }

    &__summary {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: .7rem;
    }

    .summary-time {
      color: #9298A7;
    }

    .summary-actions {
      font-size: 1.3rem;

      & > * {
        cursor: pointer;
        margin-left: 10px;
      }

      i:hover {
        color: #444;
      }

      .fa-check-circle {
        color: #8EE4A0;

        &:hover {
          color: #52d66e;
        }
      }

      .fa-stop-circle {
        color: #C3C3C3;

        &:hover {
          color: #9c9c9c;
        }
      }

      .fa-play-circle {
        color: #0B1957;

        &:hover {
          color: #2570ae;
        }
      }
    }
  }
}