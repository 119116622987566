//
// coming-soon.scss
//

.counter-number {
  font-size: 60px;
  font-weight: $font-weight-semibold;
  text-align: center;
  color: $white;
  span {
    font-size: 16px;
    font-weight: $font-weight-normal;
    display: block;
    text-transform: uppercase;
    padding-top: 5px;
  }
}

.coming-box {
  float: left;
  width: 25%;
}

.svg-rocket {
  height: 80px;
}

.rocket-clouds__bubble,
.rocket-clouds__cloud,
.rocket-rocket,
.rocket-inner__rocket-and-lines {
  fill: $primary;
}
