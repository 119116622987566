.budget-pl {
  margin-bottom: 4rem;
  td.disabled, th.disabled {
    background: #F4F6F8;
    opacity: .6;
  }

  .td-input {
    padding: 0;
  }

  input.money-input {
    border: 0;
    padding: 0 .5rem;
  }
}