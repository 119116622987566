//
// Daterange
//

.daterangepicker {
  font-family: $font-family-base;
  background-color: $card-bg;
  border-color: $gray-300;

  .calendar-table {
    background-color: $card-bg;
    border-color: $gray-300;
    .next,
    .prev {
      span {
        border-color: $gray-500;
      }
    }
  }

  th,
  td {
    padding: 5px;
    &.week {
      color: $gray-400;
    }
    &.available {
      &:hover {
        background-color: lighten($gray-200, 4%);
      }
    }
  }

  td.active,
  td.active:hover,
  .ranges li.active {
    background-color: $primary;
  }

  .ranges li:hover {
    background-color: $gray-300;
  }

  .month,
  .calendar-time {
    select {
      background-color: $input-bg;
      border-color: $input-border-color;
      color: $input-color;
    }
  }

  td {
    &.off,
    &.off.in-range,
    &.off.start-date,
    &.off.end-date {
      background-color: transparent;
      color: rgba($gray-400, 0.7);
    }

    &.in-range {
      background-color: lighten($gray-200, 4%);
      color: $gray-600;
    }
  }

  &.show-ranges.ltr {
    .drp-calendar.left {
      border-left-color: $gray-300;
    }
  }

  .drp-buttons {
    border-top-color: $gray-300;
    .btn {
      font-weight: $font-weight-medium;
    }
  }
}
