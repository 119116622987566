//
// dropdown.scss
//

.dropdown-menu {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  margin: 0;
  font-size: $font-size-base;
  position: absolute;
  z-index: 1000;

  &.show {
    top: 100% !important;
  }
}

.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}

.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="left"] {
  top: auto !important;
  animation: none !important;
}

@keyframes DropDownSlide {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  0% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
}

.dropdown-divider {
  border-color: $border-color;
}

// Dropdown Large (Custom)
.dropdown-lg {
  width: $dropdown-lg-width;
}

@include media-breakpoint-down(sm) {
  .dropdown-lg {
    width: 200px !important;
  }
}
