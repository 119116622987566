.card {
  border-radius: 10px;
  border: 1px solid $grey-8;


  &.widget-box-two {

    border: 0;
    opacity: 1;
    z-index: 99;

    &.bg-success {
      background: $pale-green !important;
    }

    &.bg-warning {
      background: $light-beige !important;
    }

    &.bg-info {
      background: $sky-blue !important;
    }

    &.bg-purple {
      background: $light-rose !important;
    }

    &.bg-pink {
      background: $pale-red !important;
    }
  }
}