.periodisation-row tr {
  td {
    background-color: rgba(52, 140, 212, 0.2);
    border: none;
    padding: 10px !important;

    &.disabled {
      background: rgba(52, 140, 212, 0.2) !important;
    }
  }
  .label {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
  }
}
tr td.periodisation-toogle {
  width: 60px;
  max-width: 60px;
  padding: 0;
  margin: 0;
  i {
    font-size: 22px;
    color: #0B1957;
    //color: $primary;
  }
}

tr td.per-arrow {
  border-left: 0 solid;
  width: 20px;
  max-width: 20px;
  padding: 0;
  text-align: left;

  i {
    font-size: 18px;
    color: #0B1957;
    //color: $primary;
  }
}
