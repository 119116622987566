//
// bootstrap-taginput.scss
//

/* Bootstrap tagsinput */
.bootstrap-tagsinput {
  box-shadow: none;
  padding: 4px 7px 4px;
  width: 100%;
  background: $input-bg;
  border-color: $input-border-color;

  input {
    color: $input-color;
    &::placeholder {
      color: $input-color;
    }
  }

  .label-info {
    background-color: $primary;
    display: inline-block;
    font-size: 11px;
    margin: 3px 1px;
    padding: 0 5px;
    border-radius: 3px;
  }
}
