.finished-step {
  .finishing-steps {
    background: #F7F7F7;
    padding: 2rem;

    h3 {
      margin-left: -1rem;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      .card-title {
        text-align: center;
      }
    }
  }
}