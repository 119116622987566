.absence-box-wrapper {
  .absence-box {
    border: 1px solid #fd7e14;
    font-size: .85rem;

    pre {
      margin-bottom: .5rem;
    }

    small {
      display: block;
      font-size: .65rem;
    }

    .box-item {
      padding: .5rem;

      &:not(:last-child) {
        border-bottom: 1px dashed #ccc;
      }
    }

  }

  .page-alert {
    display: none;
  }
}