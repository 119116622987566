//
// nav.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: $dark;
      font-weight: $font-weight-semibold;
      text-transform: uppercase;
      &.active {
        color: $primary;
      }
    }
  }
}

.nav-pills {
  > a {
    font-weight: $font-weight-semibold;
  }
}

//Navtab custom
.navtab-bg {
  li {
    > a {
      background-color: $gray-300;
      margin: 0 5px;
    }
  }
}

.tab-content {
  padding: 20px 0 0 0;
}

/* Vertial tab */
.tabs-vertical-env {
  .nav.tabs-vertical {
    .nav-link {
      color: $dark;
      text-align: center;
      text-transform: uppercase;
      &.active {
        background-color: lighten($gray-300, 4%);
        color: $primary;
      }
    }
  }
}

// Nav bordered

.nav-bordered {
  border-bottom: 2px solid rgba($gray-600, 0.2) !important;
  .nav-item {
    margin-bottom: -2px;
  }

  li {
    a {
      border: 0 !important;
      padding: 10px 20px !important;
    }
  }

  a.active {
    border-bottom: 2px solid $primary !important;
  }
}
