.single-payments2 {
  .box {
    display: flex;
  }

  .box-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    padding: 1rem;
  }

  .box-left {
    flex: 1;
    padding: 1rem 0 1rem 1rem;

    table {
      width: 98.5%;
      border: 0;

      th, td {
        border: 0;
      }

      th {
        font-size: 12px;
        font-weight: 500;
      }

      td {
        padding: .75rem;
        min-height: 35px;
      }
      thead tr {
        height: 40px;
      }
      tr {
        height: 60px;

        &.is-active {
          border: 2px solid #0B1957;
        }

        &:not(.is-active) td {
          border-bottom: 1px solid #ECEDED;
        }

        &.state {
          i.fe-check-circle, i.fe-x-circle {
            display: none;
          }

          &--partially_signed, &--pending {
            i.fe-clock, i.fe-x-circle {
              display: none
            }

            i.fe-check-circle {
              display: block;
              color: #FFAA30;
            }
          }

          &--sent_bank, &--paid {
            background: rgba(107, 213, 129, 0.1);

            i.fe-clock, i.fe-x-circle {
              display: none
            }

            i.fe-check-circle {
              display: block;
              color: #6BD581;
            }
          }

          &--fail {
            background: rgba(249, 54, 54, 0.1);

            i.fe-clock, i.fe-check-circle {
              display: none
            }

            i.fe-x-circle {
              display: block;
              color: #F93636;
            }
          }

          td.state-icon {
            padding: 0;
            text-align: center;
            color: #ADADAD;
            i {
              font-weight: 500;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}