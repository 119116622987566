.invoice-order-btn {
  margin-right: 1px;
  .dot {
     height: 4px;
     width: 4px;
    background-color: #6BD481;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 5px;
    margin-left: 8px;
    margin-top: 6px;
    position: absolute;
  }
  i {
    top: 2px;
    font-size: 14px;
    position: relative;
    bottom: 1px;
  }
}