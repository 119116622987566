.document-box {
  position: relative;
  border: 2px dashed $gray-400;
  background: #fff;
  border-radius: 6px;
  line-height: 22px;
  margin-bottom: 1rem;
  display: block;
  width: 100%;
  max-width: 100%;
  overflow: auto;

  &.document-empty {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rotate90 {
    //transform: rotate(90deg);
    img {
      transition: transform 0.3s;
    }
  }
}

.document-box .file-info,
.dropzone-wrapper .file-info {
  //position: absolute;
  //top: 0;
  //left: 0;
  background: #fafafa;
  //border-bottom: 2px solid #ccc;
  //border-right: 1px dashed #ccc;

  div {
    padding: 2px 10px;
    font-size: 10px;
    display: inline-block;
    border-right: 1px solid #ccc;
  }

  div.rotate {
    cursor: pointer;

    &:hover {
      color: $primary;
    }
  }

  div.remove {
    color: darkred;
    cursor: pointer;
  }

  div a {
    color: #6c757d;
    text-transform: lowercase;

    &:hover {
      color: $primary;
    }
  }
}

.dropzone-wrapper {
  border: 2px dashed $gray-400;
  background: #fff;
  border-radius: 6px;
  display: block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  //height: 180px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 22px;
  color: #777;
  text-align: center;
  transition: border-color 0.15s linear;

  margin-bottom: 1rem;

  input[type="file"] {
    display: none;
  }

  &:not(.file-selected) {
    height: 180px;
  }

  &.file-selected {
    padding: 0;
    text-align: left;
    height: auto;

    object {
      height: 80vh;
    }

    .dropzone-preview {
      position: relative;
      opacity: 1 !important;
      background: #fafafa;

      object {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &:hover {
    background-size: 30px 30px;
    background-image: linear-gradient(
                    -45deg,
                    #f6f6f6 25%,
                    transparent 25%,
                    transparent 50%,
                    #f6f6f6 50%,
                    #f6f6f6 75%,
                    transparent 75%,
                    transparent
    );
    animation: stripes 2s linear infinite;
  }

  &.has-error {
    border-color: #f34141;
  }

  .dropzone-message {
    pointer-events: none;
    position: relative;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);

    i {
      font-size: 45px;
      color: #ccc;
    }
  }

  .dropzone-preview {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow-x: auto;
    overflow-y: auto;
    opacity: 0;
    transition: all 500ms ease-in-out 250ms;

    object[type="image/png"] {
      max-width: 100%;
    }

    object[type="application/pdf"] {
      width: 100%;
      height: 50vh;
    }

    object[type="text/xml"] {
      width: 100%;
      height: 100%;
    }

    object[type=""] {
      width: 100%;
      height: 97%;
    }
  }
}

#importCompaniesCSV .file-selected {
  max-height: 100px;
}

.upload-page {
  .dropzone-wrapper.file-selected {
    height: auto;
  }
}
