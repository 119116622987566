.salary-form {
  .external-wrapper{
    display: flex;
    justify-content: flex-end;
    margin: 30px 0;
  }

  .external-button {
    color: #6c757d;
    padding: 0;
  }

  #cost_center-select,
  #project-select {
    .table-select__indicator-separator,
    .table-select__dropdown-indicator {
      display: block;
    }

    .table-select__control {
      padding-right: 0;
    }
  }
}