.step-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .step-header {
    .step-container {
      display: flex;
      align-items: center;

      .step-container__number {
        color: #ECEDED;
        font-size: 50px;
        font-weight: 700;
        margin-right: 1rem;
      }

      .step-container__subtitle {
        font-size: 12px;
        color: #757575;
        font-weight: 400;
        letter-spacing: .15em;
      }

      .step-container__title {
        font-size: 20px;
        color: #585858;
        font-weight: 700;
        letter-spacing: .05em;
      }
    }
  }
}