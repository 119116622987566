div[data-lastpass-icon-root] {
  display: none !important;
}

div[data-lastpass-infield] {
  display: none;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.page-title-box {
  .page-title-right {
    margin-top: 25px;
  }

  p.page-title-sub-header {
    font-size: 12px;
    margin-top: -30px;
    margin-bottom: 10px;
    color: #6c757d;
  }
}

.tab-card {
  .nav-bordered {
    border-bottom: 1px solid #e4e7ea !important;
  }

  .nav {
    background: $boxed-body-bg;

    li.nav-item {
      margin-right: 3px;
    }

    a {
      color: $menu-item;
      text-transform: none;
      font-weight: 400;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 5px 5px 0 0;
      border-bottom: 3px solid #e4e7ea !important;

      &.active {
        background: #fff;
        color: $menu-item-active;
        border: 1px solid $card-border-color !important;
        border-bottom: 0 !important;
      }
    }
  }

  .card {
    border: 1px solid $card-border-color;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.btn-group {
  button:focus,
  label.focus {
    box-shadow: none !important;
  }

  .active {
    z-index: 0 !important;
  }
}

.btn.b-purple {
  background: #BC7BBF;
  border-color: #BC7BBF;
}

.btn-outline-purple.active:disabled {
  background: #acb3e2;
  color: #444;
}

.btn-outline-primary.active:disabled {
  background: #aed1ee;
}

button.btn {
  &:focus {
    box-shadow: none !important;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.btn-draft,
  &.btn-fix {
    background: $draft;
  }

  &.btn-unpaid {
    background: $unpaid;
  }

  &.btn-prelim,
  &.btn-approval {
    background: $prelim;
  }

  &.btn-sent-bank,
  &.btn-sent_bank {
    background: $sentBank;
  }

  &.btn-investigate,
  &.btn-dispute {
    background-color: $disputed;
  }

  &.btn-paid {
    background: $paid;
  }

  &.btn-draft,
  &.btn-unpaid,
  &.btn-prelim,
  &.btn-approval,
  &.btn-investigate {
    &:hover {
      color: #fff;
    }
  }

  &.btn-outline-draft {
    color: #585858;
    border-color: $draft;

    &:hover,
    &.active {
      background-color: $draft;
      border-color: $draft;
      color: #fff;
    }
  }

  &.btn-outline-investigate {
    color: #585858;
    border-color: $investigate;

    &:hover,
    &.active {
      background-color: $investigate;
      border-color: $investigate;
      color: #fff;
    }
  }

  &.btn-outline-prelim {
    color: #585858;
    border-color: $prelim;

    &:hover,
    &.active {
      background-color: $prelim;
      border-color: $prelim;
      color: #fff;
    }
  }

  &.btn-outline-unpaid {
    color: #585858;
    border-color: $unpaid;

    &:hover,
    &.active {
      background-color: $unpaid;
      border-color: $unpaid;
      color: #fff;
    }
  }

  &.btn-outline-unpaid-expired {
    background: none;
    text-decoration: inherit;

    border-image-slice: 1;
    border-image-source: linear-gradient($unpaidWhite 50%, $expired 50%);
    color: #585858 !important;

    &:hover,
    &.active {
      background: linear-gradient($unpaidWhite 50%, $expired 50%);
      color: #222 !important;
    }
  }

  &.btn-outline-sent-bank {
    color: #585858;
    border-color: $sentBank;

    &:hover,
    &.active {
      background-color: $sentBank;
      border-color: $sentBank;
      color: #fff;
    }
  }

  &.btn-outline-paid {
    color: #585858;
    border-color: $paid;

    &:hover,
    &.active {
      background-color: $paid;
      border-color: $paid;
      color: #fff;
    }
  }
}

.btn {
  &.btn-second {
    border-color: $primary;
    color: #585858;
    background: #fff;

    i {
      color: $primary;
    }

    &:hover,
    &.active {
      background: #ededed;
    }

    &.blue {
      color: $primary;
    }
  }

  &.btn-white {
    border-color: #fff;
    color: $primary;
    background: #fff;

    i {
      color: $primary;
    }
  }
}

#customer-invoice-table {
  .table-filters-left {
    margin-bottom: 1.5rem;
  }

  .table-filters-right {
    display: block;
  }

  .hacked-checkbox {
    position: absolute;
    top: 2.6rem;
    padding-left: 0.4rem;
  }
}

#supplier-invoice-table,
#customer-invoice-table {
  //button.btn-unpaid {
  //  border-image-slice: 1;
  //  border-image-source: linear-gradient($unpaidWhite 50%, $expired 50%);
  //  background: linear-gradient($unpaidWhite 50%, $expired 50%);
  //  color: #222 !important;
  //}

  tr.unpaid {
    border-left: 8px solid $grey-8;
  }
}

.btn-outline-secondary-blue {
  .btn-outline-secondary {
    background-color: #dbdbdb;
    border-color: #adadad;
    color: #757575;

    &:first-child {
      border-right: 0;
    }

    &:not(:first-child) {
      border-left: 0;
    }

    &:hover {
      background-color: $blue;
      border-color: $blue;
      color: white;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $blue;
      border-color: $blue;
    }
  }
}


.btn-filter {
  position: relative;
  margin-left: -13px;
  border-color: #ced4da;
  color: #6c757d;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &:hover,
  &.active {
    background: $gray-300;
  }

  i.fa-toggle-off,
  i.fa-toggle-on {
    margin-left: 0.3rem;
    color: $primary;
  }

  .filter-counter {
    position: absolute;
    font-size: 9px;
    color: #6c757d;
    top: 21px;
    right: 4px;
  }
}

.popover {
  &.popover-logs,
  &.popover-comments {
    max-width: 600px;
    min-width: 400px;
    border-color: $border-color;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0;
        padding: 0;
      }
    }

    .arrow::before {
      border-left-color: $border-color;
    }

    .arrow::after {
    }
  }

  &.popover-comments {
    .arrow::before {
      //border-left: 5px solid transparent;
      //border-right: 5px solid transparent;
      border-left-color: transparent;
      border-bottom-color: $border-color;
    }
  }

  &.popover-hint {
    border: none;

    .popover-body {
      max-width: 250px;
      text-align: left;
      padding: 0.4rem 0.8rem;
      background-color: $gray-800;
      border-radius: 0.2rem;
      color: #fff;
    }

    &.bs-popover-top {
      margin-bottom: 0.5rem;

      .arrow::after {
        border-top-color: $gray-800;
      }
    }

    &.bs-popover-bottom {
      .arrow::after {
        border-bottom-color: $gray-800;
      }
    }

    &.bs-popover-left {
      .arrow::after {
        border-left-color: $gray-800;
      }
    }

    &.bs-popover-right {
      .arrow::after {
        border-right-color: $gray-800;
      }
    }
  }
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #444;
}

.space {
  display: flex;
  justify-content: space-between;
}

.space-1 > *:not(:last-child) {
  margin-right: 1rem;
}

.space-2 > *:not(:last-child) {
  margin-right: 2rem;
}

.scroller {
  max-height: 530px;
  border-top: 1px solid $border-color;

  table {
    margin-bottom: 0;

    th {
      border-top: 0;
    }
  }

  overflow-y: auto;

  .sticky-header {
    thead th {
      top: -1px !important;
    }

    .sticky1 th {
      top: 35px !important;
    }
  }
}

.totals > div {
  display: inline-block;
  border: 1px solid $border-color;
  color: #999;
  padding: 0.5rem 1rem 0.5rem 0.5rem;

  strong {
    padding-right: 0.5rem;
  }
}

.totals {
  .selected-to-pay-box {
    color: #fe9898;
    border-color: #fe9898;
  }
}

#payment-box {
  .card-body {
    display: flex;
    justify-content: space-between;

    .summary-box {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .cash-box {
        .box {
          border: 0;
          background: #f7f7f7;
        }
      }

      div.box {
        margin-right: 5px;
        margin-bottom: 5px;
        text-align: center;
        display: inline-block;
        border: 1px solid $border-color;
        min-width: 140px;
        color: #999;
        padding: 10px;
        font-weight: 500;
        font-size: 13px;
        position: relative;

        span {
          color: #adadad;
        }

        small {
          position: absolute;
          font-size: 9px;
          bottom: 2px;
          left: 0;
          width: 100%;
        }

        p {
          margin: 0;
          font-size: 18px;
          color: #1b1b1b;
        }
      }
    }

    .totals > div {
      padding: 0.65rem 1rem 0.65rem 1rem;
    }
  }

  .pay-and-connect-box {
    display: flex;
    flex-direction: row;

    .pay-btn {
      font-size: 16px;
      font-weight: 600;

      span {
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 1280px) {
  #payment-box .pay-and-connect-box {
    .d-flex {
      flex-direction: column;

      .connect-box {
        width: 300px !important;

      }
    }

  }
}

@media (max-width: 1460px) {
  #payment-box .pay-and-connect-box {
    .d-flex {
      margin-bottom: 1rem;

      .connect-box {
        width: auto;
        margin-bottom: 1rem;
      }
    }
  }
}

@media (max-width: 1820px) {
  #payment-box .card-body {
    .summary-box {
      div.box {
        min-width: 100px;
      }
    }
  }
}

.rounding-switcher {
  float: right;
  margin-bottom: 0.5rem;
}

.dropdown-menu-right {
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .dropdown-header {
    padding-bottom: 0;
    padding-left: 0.5rem;
  }
}

.border-left-content {
  border-left: 4px solid $primary;
  margin-bottom: 1rem;

  &.border-warning {
    border-left-color: $warning;
  }
}

.tooltip-comments {
  width: 500px;
  text-align: left;
  font-size: 12px;

  p {
    margin: 0;
    padding: 0;
  }
}

.navbar-custom {
  .nav-btn {
    margin-top: 18px;
    margin-left: 1rem;
    color: #fff;
    padding: 0 0.8rem;
    height: 33px;
    line-height: 33px;
    background: #2782cd;
    border-color: #fff;
    border-radius: 4px;

    i:not(.fe-chevron-right) {
      margin-right: 0.2rem;
    }

    i.fe-chevron-right {
      margin-left: 0.5rem;
      font-size: large;
    }

    &:hover {
      box-shadow: inset 0 0 5px #4e9fe1;
    }

    &.company-switcher i.fe-briefcase {
      margin-right: 0.5rem;
    }

    &.nav-plus-btn {
      @media (max-width: 450px) {
        display: none;
      }
    }
  }

  .profile-dropdown {
    .dropdown-item {
      padding: 0.45rem 0.8rem;
    }

    .dropdown-header {
      color: black;
      font-weight: 600;
      line-height: 21px;
      font-size: 14px;
    }

    .dropdown-divider {
      margin: 0.25rem 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .navbar-custom {
    .nav-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.75rem;
      padding: 0 0.5rem;

      span {
        display: none;
      }

      i:not(.fe-chevron-right) {
        margin-right: 0;
        font-size: large;
      }

      i.fe-chevron-right {
        font-size: large;
      }

      &.company-switcher i.fe-briefcase {
        margin-right: 0;
      }
    }
  }
}

.menu-switcher {
  display: flex;
  margin: 10px 12px 0 10px;
}

#budget-tooltip {
  .arrow {
    margin: 0;
    padding: 0;
    right: 8px;
  }

  .arrow::before {
    border-left-color: #6c757d;
  }

  .tooltip-inner {
    background: none;
  }

  .budget-tooltip__inner {
    border: 1px solid #ddd;
    background: #F8FDF9;
    color: #757575;
    padding: 2px 5px 2px 5px;
    border-radius: 8px;
  }
}

.fx_currency_tooltip {
  .arrow {
    display: none;
  }

  .tooltip-inner {
    border: 1px solid #fafafa;
    box-shadow: 0 1px 14px 0 #0000001A;
    background: #fff;
    color: #444;
    font-weight: 600;

    div:nth-child(1) {
      color: #5DB870;
    }
  }
}

.tooltip-permissions {
  .tooltip-inner {
    width: auto;
    max-width: none;
    border-radius: 20px;
    background: $disabledDarkGray;
    color: $white;
    font-size: 12px;
    line-height: 18px;
    padding: 2px 8px 2px 8px;
  }

  .arrow {
    display: none;
  }
}
//
//.btn-group {
//  .btn, .btn:focus {
//    color: #4D4E66;
//    background: #fff;
//    border-color: #C8CEE4;
//    font-weight: 500;
//    transition: none;
//
//  }
//
//  .btn-outline-secondary {
//    border-color: #C8CEE4;
//
//    i {
//      visibility: hidden;
//    }
//  }
//}