.bank-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;

  .bank-item {
    cursor: pointer;

    &:hover {
      background: #fafafa;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ECEDED;
    width: 100%;
    border-radius: 5px;
    padding: 15px 15px 15px 30px;
    margin-bottom: 10px;

    i {
      font-size: 18px;
      color: #ADADAD;
    }
  }
}