.bankID-login-modal {

  .modal-content {
    .modal-body {
      padding: 10px 0 30px 0;
    }

    padding: 24px 30px 15px 30px;
    border-radius: 10px;

    button.close {
      z-index: 9;
      position: absolute;
      right: 24px;
      cursor: pointer;
      font-size: 26px;

      &:hover {
        color: #000;
      }
    }

    p.modal-title {
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
      margin: 0 0 40px 0;
      color: #1B1B1B;
    }

    .modal-footer {
      border: 0;
      padding: 0;
      justify-content: flex-start;

      button {
        color: #585858;

        &:hover {
          color: #000;
        }
      }
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    ol {
      padding: 0;

      li {
        margin: 0;
        padding: 0;
      }
    }
  }
}