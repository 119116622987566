.employee-modal {
  margin-top: 50px;
  .modal-content {
    background-color: transparent;
    border: none;
    overflow-y: hidden;

    &__wrapper {
      background-color: white;
      overflow-y: auto;
    }
  }

  .modal-header {
    border-bottom: 0;
  }

  .nav {
    margin: 0;

    .nav-link {
      font-size: 13px;
      border-radius: 4px 4px 0 0;
      color: #444;
      background-color: #ADADAD;
      margin-right: 2px;
      border-color: transparent;

      &.active {
        border-color: transparent;
        background: #fff;
        color: #258DCF;
      }
    }
  }

  .close {
    line-height: 0.7;
  }

}