#export-page {
  table thead tr th.tw-50, .tw-50 {
    width: 49%;
  }

  td.expand {
    max-width: 49px;
    min-width: 49px;
  }

  .expanded {
    > td {
      padding: 0;
    }

    table {
      margin-bottom: 0;

      tbody tr:hover:not(.expanded) {
        box-shadow: none;
        background-color: inherit;
      }

      tr:not(.export-all) td {
        background: #F7F7F7;
      }

      tr:last-child td {
        border-bottom: 0;
      }
    }

    table td:first-child {
      border-left: 0;
    }

    table td:last-child {
      border-right: 0;
    }

    button.download {
      &:disabled {
        color: #757575;
        background: #DBDBDB;
        border-radius: 2.5px;
      }
    }

    div.category {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .fe-check {
        font-size: 14px;
        color: rgba(107, 213, 129, 0.7);
        font-weight: 600;
      }

      .prepared {
        font-weight: 300;
      }

      .category--name {
        flex: 1;
      }

      .category--action {
        text-align: center;
        display: flex;
        justify-content: space-between;

        & > div {
          padding-left: 0.85rem;
        }

        & > div:not(:first-child) {
          border-left: 1px solid #D9D9D9;
        }

        & > div:not(:last-child) {
          padding-right: 0.85rem;
        }
      }
    }
  }

  tr.export td {
    .category--title {
      display: list-item;
      margin-left: 1.5rem;
    }
  }

  tr.export-all td {
    &:nth-child(2) {
      font-weight: 800;
    }

    background: rgba(52, 140, 212, 0.12);

    .category--title {
      margin-left: 0;
      display: block;
    }
  }

  .export-global {
    td {
      border: 1px solid #dee2e6 !important;
    }

    tr.export {
      .category--title {
        margin-left: 0;
        display: block;
      }
    }
  }
}