@import "assets/scss/new_ui/colors";

.permissions-form {
  width: 780px;
  margin: 0 auto;

  .save {
    text-align: center;

    button {
      width: 285px;
      margin-top: 20px;
      font-weight: 600;
      border-radius: 5px;
    }
  }

  table {
    border-collapse: separate;
    width: 100%;
    table-layout: fixed;
    color: #000;

    tr td:first-of-type, tr th:first-of-type {
      width: 190px;
    }
  }

  .headers {
    padding-right: 25px;

    table {

      th {

        &>div {
          display: flex;
          flex-direction: column;
          align-items: center;

          .checkbox {
            width: 100%;
            height: 100%;
            background-color: $blue-40;
          }

          .checkbox-wrapper {
            width: 16px;
            height: 16px;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;

            cursor: pointer;
          }

          .allowed {
            border: 1px solid $blue-40;

            .checkbox {
              background-color: inherit;
            }
          }

          .partially-checked {
            border: 1px solid $blue-40;

            .checkbox {
              width: 8px;
              height: 8px;
              margin: 0;
            }
          }
        }

        .label {
          font-weight: 600;
          margin-bottom: 3px;
        }
      }
    }
  }

  .body {

    &>div:first-of-type {
      padding-right: 25px;
    }

    table {
      border-spacing: 2px 8px;

      .checked {
        width: 16px;
        height: 16px;
        background-color: $blue-40;
        cursor: pointer;
      }

      .allowed {
        width: 16px;
        height: 16px;
        border: 1px solid $blue-40;
        background-color: inherit;
        border-radius: 2px;

        cursor: pointer;

        .checkbox {
          background-color: inherit;

          cursor: pointer;

          width: 100%;
          height: 100%;
        }
      }

      .arrow-btn {
        position: absolute;
        cursor: pointer;
        padding: 0;

        & i {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.2;
        }

        & .fe-chevron-down {
          color: #ADADAD;
        }
      }

      .parent {

        & td:first-of-type {
          padding-left: 10px;
          font-weight: 600;
          position: relative;
          cursor: pointer;

          & span {
            margin-left: 30px;
          }
        }

        .checked .checkbox {
          background-color: $blue-40;

          cursor: pointer;

          width: 100%;
          height: 100%;
        }

        .partially-checked .checkbox {
          background-color: $blue-40;
          cursor: pointer;
        }

      }

      .parent.expand {
        & td {
          background-color: #EBF3FB;
        }
      }

      .child {

        & td:first-of-type {
          padding-left: 35px;

          & .dot {
            margin-right: 10px;
          }
        }

        .checked {
          padding: 0;
          width: 8px;
          height: 8px;
          background-color: $blue-40;
          cursor: pointer;
        }

      }

      & td {
        background-color: #F7F7F7;
        padding: 8px;

        &>div {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          margin: 0 auto;

          width: 16px;
          height: 16px;

          &.partially-checked {
            border: 1px solid $blue-40;

            button {
              width: 8px;
              height: 8px;
            }
          }
        }
      }

    }
  }

  button {
    border: 0;
    background-color: inherit;
  }

  & td,
  & th {
    width: 80px;
  }

  .checkbox {
    padding: 0;
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background-color: #E9E9E9;
    cursor: inherit;
  }

  .company-name {
    color: $blue-40;
    font-weight: 600;
  }
}