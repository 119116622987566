@import "./colors.scss";

.navbar-custom {
  &.default-layout {
    background: $blue-80;
  }

  &.st-layout {
    background: $dusty-blue;
  }

  &.office-support-layout {
    background: $beige;
  }

  .nav-btn {
    background: transparent;
  }
}


