.agency-consult-perm-modal {
  .modal-header {
    border-bottom: 0;
    padding-left: 66px;

    .card-title {
      font-size: 18px;
    }
  }

  .agency-consult-perm-form {
    .permissions {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding-left: 96px;
      margin-bottom: 46px;
      font-size: 16px;
      line-height: 24px;
    }

    .permission-wrapper {
      display: flex;
      gap: 20px;
      align-items: center;

      .checkbox {
        width: 100%;
        height: 100%;
        padding: 0;
        border: 1px solid #0B1957;
        background-color: inherit;
        border-radius: 2px;
      }

      .checkbox-wrapper {
        width: 15px;
        height: 15px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .checked {
      .checkbox {
        width: 100%;
        height: 100%;
        background-color: #0B1957;
      }
    }

    .disabled {
      .checkbox {
        padding: 0;
        width: 8px;
        height: 8px;
        border: 0;
        border-radius: 2px;
        background-color: #e9e9e9;
        cursor: inherit;
      }
    }

    .btn {
      border-radius: 7px;
      padding: 9px 29px;
      margin-left: 67px;
    }
  }
}
