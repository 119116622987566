.connection-results {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: #6BD581;
    font-weight: 600;
    font-size: 14px;
  }

  button {
    padding: 5px 40px;
    border-radius: 5px;
  }
}