.status-list-detail-modal {
  .modal-header {
    border-bottom: 0;

    > div {
      padding-top: 1.5rem;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1, h2, h3 {
        margin: 0 0 4px 0;
      }

      h1 {
        color: #040B2C;
        font-size: 16px;
        font-weight: 600;
        line-height: 17px;
      }

      h2 {
        font-weight: 600;
        color: #456EE0;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      h3 {
        font-weight: 400;
        color: #7C8296;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }

  .event-period-tag {
    display: inline-block;
    margin-left: .5rem;

    .period-tag {
      border-radius: 4px;
      background: #F3F4F4;
      color: #ACACAC;
      padding: 0 6px;

      span {
        font-weight: 500;
        line-height: 18px;
        font-size: 12px;
      }

      &.green {
        background: #6BD4811A;

        span {
          color: #60BD74;
        }
      }

      &.border-warn {
        border: 1px dashed #FFAA30;
      }

      &.border-danger {
        border: 1px solid #FD6767;
      }

      &.purple {
        background: #C670CA1A;

        span {
          color: #C670CA;
        }
      }
    }
  }


  .modal-body {
    label.title-label {
      color: #7C8296;
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
    }

    .content {
      display: flex;

      > div {
        flex: 1;
      }

      div.checklist, div.description, div.form {
        margin: 0 10px;
      }

      div.checklist {

      }

      div.description {
        flex: 1.6;

        .body {
          p {
            flex: 1;
            padding: 16px 20px;
            margin: 0;
            background: #F7F7F7;
            border: 1px solid #ECEDED;
            border-radius: 5px;
            white-space: pre-wrap;

          }
        }
      }

      .comments {
        margin-top: 50px;

        .comments-box {
          border-radius: 5px;
          border: 1px solid #ECEDED;
          background: #F7F7F7;
          padding-bottom: 15px;

          .comment-item {
            padding: 0 20px;
            margin-top: .5rem;

            .comment-content {
              display: flex;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              word-break: break-all;

              .comment-initial {
                display: flex;
                justify-content: center;
                margin-right: 10px;

                .badge {
                  background: #456EE024;
                  width: 30px;
                  height: 30px;
                  border-radius: 20px;
                  color: #0B1957;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: -1px;
                }
              }

              border-radius: 4px;
              box-shadow: 0 1px 6px 0 #00000014;
              background: #fff;
              padding: 8px 12px;
            }

            .comment-date {
              color: #AAB0C6;
              font-size: 12px;
              font-weight: 400;
              text-align: right;
              margin-top: 5px;
            }
          }

          .comment-prompt {
            margin-top: .5rem;
            padding: 0 20px;

            label {
              margin-bottom: 0;
            }

            button {
              margin-top: .5rem;
              background: #22429A;
              font-weight: 500;
              border: 0;

              &:hover {
                background: #284db4;
              }
            }
          }
        }
      }
    }
  }

  .modal-footer {
    .configure-btn {
      background: #456EE01F;
      border: 1px solid #456EE01F;
      font-weight: 500;
      color: #4D4E66;

      &:hover {
        border: 1px solid #456EE0;
      }

      i {
        color: #456EE0;
      }
    }

    border-top: 0;
    justify-content: flex-start;
  }
}

@media (min-width: 1200px) {
  .status-list-detail-modal.modal-xl {
    max-width: 1300px;
  }
}