@import "assets/scss/new_ui/colors.scss";

.flow-list {

  .toggle-button {
    border: 1px solid #DEE2ED;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    color: $grey-80;
  }

  .card {
    margin-right: 5px;
    border-radius: 8px;
    border: 1px solid #ececec;
    box-shadow: 0 2px 6px 0 #0000001f;
    background: white;

    &.default {
      .card-title {
        color: $blue-30;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.01em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }

    &:not(.default) {
      .card-title {
        color: $grey-80;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.01em;
        text-align: left;
      }
    }

    .title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .card-title {
        margin: 0;
      }

      .fe-edit-2 {
        font-size: 16px;
        color: transparent;
        transition: color 0.2s ease;

        &:hover {
          color: $blue-40;
        }
      }
    }

    &:hover {
      .fe-edit-2 {
        color: $grey-60;
      }
    }
  }
}

.steps-container {
  display: flex;
  align-items: center;
  gap: 2px;
}

.step-container {
  display: flex;
  align-items: center;
}

.approvers-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
}

.arrow {
  font-size: 18px;
  color: $grey-60;
  align-self: center;
  gap: 10px
}

.flow-button {
  width: 132px;
  height: 90px;
  padding: 0 17px;
  border: 1px dashed #e7e9ed !important;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #000;
  background: transparent;
  transition: background 0.3s ease;

  &:hover {
    background: #456ee01a;
    border-color: #e7e9ed !important;
  }

  &:active,
  &:focus {
    background: #456ee029;
    border-color: #e7e9ed !important;
    outline: none;
  }

  &.disabled {
    background: #f7f7f7 !important;
    border-color: #e7e9ed !important;
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.flow-picker-default-title {
  color: $blue-30;
}
