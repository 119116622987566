//
// buttons.scss
//

.btn {
  color: $white;

  .mdi {
    &:before {
      margin-top: -1px;
    }
  }
}

.btn-rounded {
  border-radius: 2em;
}

//
// light button
//
.btn-light,
.btn-white {
  color: $gray-900;
}

.btn-dark {
  color: $gray-200;
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  background-color: transparent;
  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
    border-color: transparent;
  }
  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    border-color: transparent;
    box-shadow: none;
  }
  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  } // No need for an active state here
}
//
// Alternate buttons
//
@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

//
// Button Extra Small Size
//

.btn-xs {
  padding: 0.2rem 0.6rem;
  font-size: 0.75rem;
  border-radius: 0.15rem;
}


.btn-option {
  font-weight: 500;
  color: #585858;
  background-color: transparent;
  border-color: #DFE2E6;
  i {
    font-size: 18px;
    color: $primary;
    vertical-align: text-top;
  }
  &.active {
    background-color: rgba(52, 140, 212, 0.15) !important;
  }
  &:disabled,
  &.disabled {
    background-color: transparent !important;
    pointer-events: none;
    border-color: #DFE2E6;
    opacity: 0.6 !important;
  }
  &:disabled.active {
    background-color: rgba(52, 140, 212, 0.15) !important;
  }
}
