//
// ion-rangeslider.scss
//
.irs--modern {
  .irs-bar,
  .irs-to,
  .irs-from,
  .irs-single {
    background: $primary !important;
  }
  .irs-to,
  .irs-from,
  .irs-single {
    &:before {
      border-top-color: $primary;
    }
  }
  .irs-line {
    background: $gray-300;
    border-color: $gray-300;
  }

  .irs-min,
  .irs-max {
    color: $gray-600;
    background: $gray-300;
  }
  .irs-grid-text {
    font-size: 11px;
    color: $gray-600;
  }
  .irs-handle {
    > i {
      &:nth-child(1) {
        width: 8px;
        height: 8px;
      }
    }
  }
}
