@import "assets/scss/new_ui/colors";

.btn.step-status {
  padding: .1rem .8rem;
  border-radius: 4px;
  font-weight: 500;
  color: $grey-100;

  &.in_progress {
    border-color: $orange-10;
    background: $orange-6;
    &:hover {
      background: $orange-8;
    }
  }

  &.not_started {
    border-color: $blue-10;
    background: #fff;
    color: $grey-60;
    &:hover {
      background: $grey-6;
    }
  }

  &.finished {
    border-color: $green-20;
    background: $green-6;
    color: $grey-100;
    &:hover {
      background: $green-10;
    }
  }

  &.error {
    border-color: $red-40;
    background: $red-6;
    &:hover {
      background: $red-8;
    }
  }

  &.locked {
    border-color: $grey-10;
    background: $grey-10;
    color: $grey-60;
    &:hover {
      background: $grey-30;
    }
  }

  &.skipped {
    border-color: $blue-10;
    background: $blue-4;
    color: $grey-100;
    &:hover {
      background: $blue-6;
    }
  }
}

.step-status-tooltip {
  .arrow {
    display: none;
  }

  .tooltip-inner {
    display: inline-flex;
    padding-top: 4.5px;
    padding-bottom: 2px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: #757575;
    white-space: nowrap;
    width: auto !important;
    max-width: none;
  }
}

.step-actions {
  .step-status {
    &.in_progress {
      border-color: $orange-10 !important;
      background: $orange-6 !important;
      &:hover {
        background: $orange-8 !important;
      }
      &:active {
        background: $orange-10 !important;
      }
      &:focus {
        outline: 2px solid $orange-40 !important;
      }
    }

    &.not_started {
      border-color: $blue-10 !important;
      background: #fff !important;
      color: $grey-60 !important;
      &:hover {
        background: $grey-6 !important;
      }
      &:active {
        background: $grey-8 !important;
      }
      &:focus {
        outline: 2px solid $grey-30 !important;
      }
    }

    &.finished {
      border-color: $green-20 !important;
      background: $green-6 !important;
      color: $grey-100 !important;
      &:hover {
        background: $green-10 !important;
      }
      &:active {
        background: $green-20 !important;
      }
      &:focus {
        outline: 2px solid $green-40 !important;
      }
    }

    &.error {
      border-color: $red-40 !important;
      background: $red-6 !important;
      &:hover {
        background: $red-8 !important;
      }
      &:active {
        background: $red-10 !important;
      }
      &:focus {
        outline: 2px solid $red-40 !important;
      }
    }

    &.locked {
      border-color: $grey-10 !important;
      background: $grey-10 !important;
      color: $grey-60 !important;
      &:hover {
        background: $grey-30 !important;
      }
      &:active {
        background: $grey-40 !important;
      }
      &:focus {
        outline: 2px solid $grey-60 !important;
      }
    }

    &.skipped {
      border-color: $blue-10 !important;
      background: $blue-4 !important;
      color: $grey-100 !important;
      &:hover {
        background: $blue-6 !important;
      }
      &:active {
        background: $blue-8 !important;
      }
      &:focus {
        outline: 2px solid $blue-20 !important;
      }
    }
  }
}

