@import "../../../../assets/scss/bflow/variables.scss";

.breadcrumbs {
  margin-left: 1rem;
  height: 70px;
  display: flex;
  align-items: center;
  font-size: 13px;

  @media (max-width: 600px) {
    & > :not(:first-child) {
      display: none;
    }
  }

  .breadcrumbs__divider {
   opacity: .4;
  }

  &__item {
    font-weight: 400;
    padding: 0 6px;
    display: block;
    border-radius: 3px;

    line-height: 33px;
    color: #fff;

    &.active:hover {
      color: #fff;
      text-decoration: underline;
    }

    &--section {
    }

    &--disabled {
      font-weight: 600;
    }

    &--back {
      padding: 0 12px 0 6px;
      font-weight: 500;
      color: #fff;
      margin-right: 1.5rem;
      background: transparent;
      box-shadow: none;
      outline: none;
      border-radius: 4px;
      border: 0;
      vertical-align: middle;
      display: flex;
      align-items: center;

      i {
        font-size: 16px;
      }

      &:hover {
        background: #323D6F;
      }

      &:active, &.active {
        background: #3F4A7C;
      }

      &:disabled {
        background: #323D6F;
        color: #7C8296;
      }
    }


    @media (max-width: 550px) {
      &--back {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 31px;

        .fe-chevron-left {
          font-size: 20px;
        }
      }

      &--back-text {
        display: none;
      }

      &:not(:first-child) {
        display: none;
      }
    }
  }

  &__divider {
    margin: 0;
    font-size: 14px;
    display: block;
    color: #fff;
  }
}

.with-fyear-select {
  .breadcrumbs > :not(:first-child) {
    @media (max-width: 1100px) {
      display: none;
    }
  }
}


.office-support-layout .breadcrumbs__item {
  &--back {
    &:hover {
      background: #A37560;
    }

    &:active, &.active {
      background: #D79F86;
    }

    &:disabled {
      background: #C69883;
      color: #7C8296;
    }
  }
}
.st-layout  .breadcrumbs__item {
  &--back {
    &:hover {
      background: #467CB0;
    }

    &:active, &.active {
      background: #649ACE;
    }

    &:disabled {
      background: #649ACE;
      color: #7C8296;
    }
  }
}